msgid ""
msgstr ""
"POT-Creation-Date: 2024-10-11 17:53+0200\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=utf-8\n"
"Content-Transfer-Encoding: 8bit\n"
"X-Generator: @lingui/cli\n"
"Language: en\n"
"Project-Id-Version: \n"
"Report-Msgid-Bugs-To: \n"
"PO-Revision-Date: \n"
"Last-Translator: \n"
"Language-Team: \n"
"Plural-Forms: \n"

#: src/components/account-block.jsx:135
msgid "Locked"
msgstr "Locked"

#: src/components/account-block.jsx:141
msgid "Posts: {0}"
msgstr "Posts: {0}"

#: src/components/account-block.jsx:146
msgid "Last posted: {0}"
msgstr "Last posted: {0}"

#: src/components/account-block.jsx:161
#: src/components/account-info.jsx:634
msgid "Automated"
msgstr "Automated"

#: src/components/account-block.jsx:168
#: src/components/account-info.jsx:639
#: src/components/status.jsx:480
#: src/pages/catchup.jsx:1471
msgid "Group"
msgstr "Group"

#: src/components/account-block.jsx:178
msgid "Mutual"
msgstr "Mutual"

#: src/components/account-block.jsx:182
#: src/components/account-info.jsx:1677
msgid "Requested"
msgstr "Requested"

#: src/components/account-block.jsx:186
#: src/components/account-info.jsx:416
#: src/components/account-info.jsx:742
#: src/components/account-info.jsx:756
#: src/components/account-info.jsx:1668
#: src/components/nav-menu.jsx:193
#: src/components/shortcuts-settings.jsx:137
#: src/pages/following.jsx:20
#: src/pages/following.jsx:134
msgid "Following"
msgstr "Following"

#: src/components/account-block.jsx:190
#: src/components/account-info.jsx:1059
msgid "Follows you"
msgstr "Follows you"

#: src/components/account-block.jsx:198
msgid "{followersCount, plural, one {# follower} other {# followers}}"
msgstr "{followersCount, plural, one {# follower} other {# followers}}"

#: src/components/account-block.jsx:207
#: src/components/account-info.jsx:680
msgid "Verified"
msgstr "Verified"

#: src/components/account-block.jsx:222
#: src/components/account-info.jsx:777
msgid "Joined <0>{0}</0>"
msgstr "Joined <0>{0}</0>"

#: src/components/account-info.jsx:57
msgid "Forever"
msgstr "Forever"

#: src/components/account-info.jsx:377
msgid "Unable to load account."
msgstr "Unable to load account."

#: src/components/account-info.jsx:385
msgid "Go to account page"
msgstr "Go to account page"

#: src/components/account-info.jsx:413
#: src/components/account-info.jsx:702
#: src/components/account-info.jsx:732
msgid "Followers"
msgstr "Followers"

#: src/components/account-info.jsx:419
#: src/components/account-info.jsx:773
#: src/pages/account-statuses.jsx:484
#: src/pages/search.jsx:237
#: src/pages/search.jsx:384
msgid "Posts"
msgstr "Posts"

#: src/components/account-info.jsx:427
#: src/components/account-info.jsx:1115
#: src/components/compose.jsx:2463
#: src/components/media-alt-modal.jsx:45
#: src/components/media-modal.jsx:283
#: src/components/status.jsx:1700
#: src/components/status.jsx:1717
#: src/components/status.jsx:1841
#: src/components/status.jsx:2442
#: src/components/status.jsx:2445
#: src/pages/account-statuses.jsx:529
#: src/pages/accounts.jsx:109
#: src/pages/hashtag.jsx:199
#: src/pages/list.jsx:158
#: src/pages/public.jsx:115
#: src/pages/status.jsx:1169
#: src/pages/trending.jsx:472
msgid "More"
msgstr "More"

#: src/components/account-info.jsx:439
msgid "<0>{displayName}</0> has indicated that their new account is now:"
msgstr "<0>{displayName}</0> has indicated that their new account is now:"

#: src/components/account-info.jsx:584
#: src/components/account-info.jsx:1273
msgid "Handle copied"
msgstr "Handle copied"

#: src/components/account-info.jsx:587
#: src/components/account-info.jsx:1276
msgid "Unable to copy handle"
msgstr "Unable to copy handle"

#: src/components/account-info.jsx:593
#: src/components/account-info.jsx:1282
msgid "Copy handle"
msgstr "Copy handle"

#: src/components/account-info.jsx:599
msgid "Go to original profile page"
msgstr "Go to original profile page"

#: src/components/account-info.jsx:606
msgid "View profile image"
msgstr "View profile image"

#: src/components/account-info.jsx:612
msgid "View profile header"
msgstr "View profile header"

#: src/components/account-info.jsx:629
msgid "In Memoriam"
msgstr "In Memoriam"

#: src/components/account-info.jsx:709
#: src/components/account-info.jsx:747
msgid "This user has chosen to not make this information available."
msgstr "This user has chosen to not make this information available."

#: src/components/account-info.jsx:802
msgid "{0} original posts, {1} replies, {2} boosts"
msgstr "{0} original posts, {1} replies, {2} boosts"

#: src/components/account-info.jsx:818
msgid "{0, plural, one {{1, plural, one {Last 1 post in the past 1 day} other {Last 1 post in the past {2} days}}} other {{3, plural, one {Last {4} posts in the past 1 day} other {Last {5} posts in the past {6} days}}}}"
msgstr "{0, plural, one {{1, plural, one {Last 1 post in the past 1 day} other {Last 1 post in the past {2} days}}} other {{3, plural, one {Last {4} posts in the past 1 day} other {Last {5} posts in the past {6} days}}}}"

#: src/components/account-info.jsx:831
msgid "{0, plural, one {Last 1 post in the past year(s)} other {Last {1} posts in the past year(s)}}"
msgstr "{0, plural, one {Last 1 post in the past year(s)} other {Last {1} posts in the past year(s)}}"

#: src/components/account-info.jsx:855
#: src/pages/catchup.jsx:70
msgid "Original"
msgstr "Original"

#: src/components/account-info.jsx:859
#: src/components/status.jsx:2230
#: src/pages/catchup.jsx:71
#: src/pages/catchup.jsx:1445
#: src/pages/catchup.jsx:2056
#: src/pages/status.jsx:892
#: src/pages/status.jsx:1494
msgid "Replies"
msgstr "Replies"

#: src/components/account-info.jsx:863
#: src/pages/catchup.jsx:72
#: src/pages/catchup.jsx:1447
#: src/pages/catchup.jsx:2068
#: src/pages/settings.jsx:1115
msgid "Boosts"
msgstr "Boosts"

#: src/components/account-info.jsx:869
msgid "Post stats unavailable."
msgstr "Post stats unavailable."

#: src/components/account-info.jsx:900
msgid "View post stats"
msgstr "View post stats"

#: src/components/account-info.jsx:1063
msgid "Last post: <0>{0}</0>"
msgstr "Last post: <0>{0}</0>"

#: src/components/account-info.jsx:1077
msgid "Muted"
msgstr "Muted"

#: src/components/account-info.jsx:1082
msgid "Blocked"
msgstr "Blocked"

#: src/components/account-info.jsx:1091
msgid "Private note"
msgstr "Private note"

#: src/components/account-info.jsx:1148
msgid "Mention <0>@{username}</0>"
msgstr "Mention <0>@{username}</0>"

#: src/components/account-info.jsx:1160
msgid "Translate bio"
msgstr "Translate bio"

#: src/components/account-info.jsx:1171
msgid "Edit private note"
msgstr "Edit private note"

#: src/components/account-info.jsx:1171
msgid "Add private note"
msgstr "Add private note"

#: src/components/account-info.jsx:1191
msgid "Notifications enabled for @{username}'s posts."
msgstr "Notifications enabled for @{username}'s posts."

#: src/components/account-info.jsx:1192
msgid "Notifications disabled for @{username}'s posts."
msgstr "Notifications disabled for @{username}'s posts."

#: src/components/account-info.jsx:1204
msgid "Disable notifications"
msgstr "Disable notifications"

#: src/components/account-info.jsx:1205
msgid "Enable notifications"
msgstr "Enable notifications"

#: src/components/account-info.jsx:1222
msgid "Boosts from @{username} enabled."
msgstr "Boosts from @{username} enabled."

#: src/components/account-info.jsx:1223
msgid "Boosts from @{username} disabled."
msgstr "Boosts from @{username} disabled."

#: src/components/account-info.jsx:1234
msgid "Disable boosts"
msgstr "Disable boosts"

#: src/components/account-info.jsx:1234
msgid "Enable boosts"
msgstr "Enable boosts"

#: src/components/account-info.jsx:1250
#: src/components/account-info.jsx:1260
#: src/components/account-info.jsx:1861
msgid "Add/Remove from Lists"
msgstr "Add/Remove from Lists"

#: src/components/account-info.jsx:1299
#: src/components/status.jsx:1143
msgid "Link copied"
msgstr "Link copied"

#: src/components/account-info.jsx:1302
#: src/components/status.jsx:1146
msgid "Unable to copy link"
msgstr "Unable to copy link"

#: src/components/account-info.jsx:1308
#: src/components/shortcuts-settings.jsx:1056
#: src/components/status.jsx:1152
#: src/components/status.jsx:3218
msgid "Copy"
msgstr "Copy"

#: src/components/account-info.jsx:1323
#: src/components/shortcuts-settings.jsx:1074
#: src/components/status.jsx:1168
msgid "Sharing doesn't seem to work."
msgstr "Sharing doesn't seem to work."

#: src/components/account-info.jsx:1329
#: src/components/status.jsx:1174
msgid "Share…"
msgstr "Share…"

#: src/components/account-info.jsx:1349
msgid "Unmuted @{username}"
msgstr "Unmuted @{username}"

#: src/components/account-info.jsx:1361
msgid "Unmute <0>@{username}</0>"
msgstr "Unmute <0>@{username}</0>"

#: src/components/account-info.jsx:1377
msgid "Mute <0>@{username}</0>…"
msgstr "Mute <0>@{username}</0>…"

#: src/components/account-info.jsx:1409
msgid "Muted @{username} for {0}"
msgstr "Muted @{username} for {0}"

#: src/components/account-info.jsx:1421
msgid "Unable to mute @{username}"
msgstr "Unable to mute @{username}"

#: src/components/account-info.jsx:1442
msgid "Remove <0>@{username}</0> from followers?"
msgstr "Remove <0>@{username}</0> from followers?"

#: src/components/account-info.jsx:1462
msgid "@{username} removed from followers"
msgstr "@{username} removed from followers"

#: src/components/account-info.jsx:1474
msgid "Remove follower…"
msgstr "Remove follower…"

#: src/components/account-info.jsx:1485
msgid "Block <0>@{username}</0>?"
msgstr "Block <0>@{username}</0>?"

#: src/components/account-info.jsx:1509
msgid "Unblocked @{username}"
msgstr "Unblocked @{username}"

#: src/components/account-info.jsx:1517
msgid "Blocked @{username}"
msgstr "Blocked @{username}"

#: src/components/account-info.jsx:1525
msgid "Unable to unblock @{username}"
msgstr "Unable to unblock @{username}"

#: src/components/account-info.jsx:1527
msgid "Unable to block @{username}"
msgstr "Unable to block @{username}"

#: src/components/account-info.jsx:1537
msgid "Unblock <0>@{username}</0>"
msgstr "Unblock <0>@{username}</0>"

#: src/components/account-info.jsx:1546
msgid "Block <0>@{username}</0>…"
msgstr "Block <0>@{username}</0>…"

#: src/components/account-info.jsx:1563
msgid "Report <0>@{username}</0>…"
msgstr "Report <0>@{username}</0>…"

#: src/components/account-info.jsx:1583
#: src/components/account-info.jsx:2094
msgid "Edit profile"
msgstr "Edit profile"

#: src/components/account-info.jsx:1619
msgid "Withdraw follow request?"
msgstr "Withdraw follow request?"

#: src/components/account-info.jsx:1620
msgid "Unfollow @{0}?"
msgstr "Unfollow @{0}?"

#: src/components/account-info.jsx:1671
msgid "Unfollow…"
msgstr "Unfollow…"

#: src/components/account-info.jsx:1680
msgid "Withdraw…"
msgstr "Withdraw…"

#: src/components/account-info.jsx:1687
#: src/components/account-info.jsx:1691
#: src/pages/hashtag.jsx:261
msgid "Follow"
msgstr "Follow"

#: src/components/account-info.jsx:1802
#: src/components/account-info.jsx:1856
#: src/components/account-info.jsx:1989
#: src/components/account-info.jsx:2089
#: src/components/account-sheet.jsx:37
#: src/components/compose.jsx:797
#: src/components/compose.jsx:2419
#: src/components/compose.jsx:2892
#: src/components/compose.jsx:3100
#: src/components/compose.jsx:3330
#: src/components/drafts.jsx:58
#: src/components/embed-modal.jsx:12
#: src/components/generic-accounts.jsx:142
#: src/components/keyboard-shortcuts-help.jsx:39
#: src/components/list-add-edit.jsx:33
#: src/components/media-alt-modal.jsx:33
#: src/components/media-modal.jsx:247
#: src/components/notification-service.jsx:156
#: src/components/report-modal.jsx:75
#: src/components/shortcuts-settings.jsx:227
#: src/components/shortcuts-settings.jsx:580
#: src/components/shortcuts-settings.jsx:780
#: src/components/status.jsx:2943
#: src/components/status.jsx:3182
#: src/components/status.jsx:3680
#: src/pages/accounts.jsx:36
#: src/pages/catchup.jsx:1581
#: src/pages/filters.jsx:224
#: src/pages/list.jsx:275
#: src/pages/notifications.jsx:869
#: src/pages/notifications.jsx:1083
#: src/pages/settings.jsx:69
#: src/pages/status.jsx:1256
msgid "Close"
msgstr "Close"

#: src/components/account-info.jsx:1807
msgid "Translated Bio"
msgstr "Translated Bio"

#: src/components/account-info.jsx:1901
msgid "Unable to remove from list."
msgstr "Unable to remove from list."

#: src/components/account-info.jsx:1902
msgid "Unable to add to list."
msgstr "Unable to add to list."

#: src/components/account-info.jsx:1921
#: src/pages/lists.jsx:104
msgid "Unable to load lists."
msgstr "Unable to load lists."

#: src/components/account-info.jsx:1925
msgid "No lists."
msgstr "No lists."

#: src/components/account-info.jsx:1936
#: src/components/list-add-edit.jsx:37
#: src/pages/lists.jsx:58
msgid "New list"
msgstr "New list"

#: src/components/account-info.jsx:1994
msgid "Private note about <0>@{0}</0>"
msgstr "Private note about <0>@{0}</0>"

#: src/components/account-info.jsx:2024
msgid "Unable to update private note."
msgstr "Unable to update private note."

#: src/components/account-info.jsx:2047
#: src/components/account-info.jsx:2217
msgid "Cancel"
msgstr "Cancel"

#: src/components/account-info.jsx:2052
msgid "Save & close"
msgstr "Save & close"

#: src/components/account-info.jsx:2145
msgid "Unable to update profile."
msgstr "Unable to update profile."

#: src/components/account-info.jsx:2152
#: src/components/list-add-edit.jsx:102
msgid "Name"
msgstr "Name"

#: src/components/account-info.jsx:2165
msgid "Bio"
msgstr "Bio"

#: src/components/account-info.jsx:2178
msgid "Extra fields"
msgstr "Extra fields"

#: src/components/account-info.jsx:2184
msgid "Label"
msgstr "Label"

#: src/components/account-info.jsx:2187
msgid "Content"
msgstr "Content"

#: src/components/account-info.jsx:2220
#: src/components/list-add-edit.jsx:147
#: src/components/shortcuts-settings.jsx:712
#: src/pages/filters.jsx:554
#: src/pages/notifications.jsx:935
msgid "Save"
msgstr "Save"

#: src/components/account-info.jsx:2273
msgid "username"
msgstr "username"

#: src/components/account-info.jsx:2277
msgid "server domain name"
msgstr "server domain name"

#: src/components/background-service.jsx:149
msgid "Cloak mode disabled"
msgstr "Cloak mode disabled"

#: src/components/background-service.jsx:149
msgid "Cloak mode enabled"
msgstr "Cloak mode enabled"

#: src/components/columns.jsx:19
#: src/components/nav-menu.jsx:184
#: src/components/shortcuts-settings.jsx:137
#: src/components/timeline.jsx:445
#: src/pages/catchup.jsx:876
#: src/pages/filters.jsx:89
#: src/pages/followed-hashtags.jsx:40
#: src/pages/home.jsx:53
#: src/pages/notifications.jsx:521
msgid "Home"
msgstr "Home"

#: src/components/compose-button.jsx:49
#: src/compose.jsx:37
msgid "Compose"
msgstr "Compose"

#: src/components/compose.jsx:392
msgid "You have unsaved changes. Discard this post?"
msgstr "You have unsaved changes. Discard this post?"

#: src/components/compose.jsx:614
#: src/components/compose.jsx:630
#: src/components/compose.jsx:1337
#: src/components/compose.jsx:1598
msgid "{maxMediaAttachments, plural, one {You can only attach up to 1 file.} other {You can only attach up to # files.}}"
msgstr "{maxMediaAttachments, plural, one {You can only attach up to 1 file.} other {You can only attach up to # files.}}"

#: src/components/compose.jsx:778
msgid "Pop out"
msgstr "Pop out"

#: src/components/compose.jsx:785
msgid "Minimize"
msgstr "Minimize"

#: src/components/compose.jsx:821
msgid "Looks like you closed the parent window."
msgstr "Looks like you closed the parent window."

#: src/components/compose.jsx:828
msgid "Looks like you already have a compose field open in the parent window and currently publishing. Please wait for it to be done and try again later."
msgstr "Looks like you already have a compose field open in the parent window and currently publishing. Please wait for it to be done and try again later."

#: src/components/compose.jsx:833
msgid "Looks like you already have a compose field open in the parent window. Popping in this window will discard the changes you made in the parent window. Continue?"
msgstr "Looks like you already have a compose field open in the parent window. Popping in this window will discard the changes you made in the parent window. Continue?"

#: src/components/compose.jsx:875
msgid "Pop in"
msgstr "Pop in"

#: src/components/compose.jsx:885
msgid "Replying to @{0}’s post (<0>{1}</0>)"
msgstr "Replying to @{0}’s post (<0>{1}</0>)"

#: src/components/compose.jsx:895
msgid "Replying to @{0}’s post"
msgstr "Replying to @{0}’s post"

#: src/components/compose.jsx:908
msgid "Editing source post"
msgstr "Editing source post"

#: src/components/compose.jsx:955
msgid "Poll must have at least 2 options"
msgstr "Poll must have at least 2 options"

#: src/components/compose.jsx:959
msgid "Some poll choices are empty"
msgstr "Some poll choices are empty"

#: src/components/compose.jsx:972
msgid "Some media have no descriptions. Continue?"
msgstr "Some media have no descriptions. Continue?"

#: src/components/compose.jsx:1024
msgid "Attachment #{i} failed"
msgstr "Attachment #{i} failed"

#: src/components/compose.jsx:1118
#: src/components/status.jsx:2026
#: src/components/timeline.jsx:992
msgid "Content warning"
msgstr "Content warning"

#: src/components/compose.jsx:1134
msgid "Content warning or sensitive media"
msgstr "Content warning or sensitive media"

#: src/components/compose.jsx:1170
#: src/components/status.jsx:93
#: src/pages/settings.jsx:297
msgid "Public"
msgstr "Public"

#: src/components/compose.jsx:1175
#: src/components/nav-menu.jsx:386
#: src/components/shortcuts-settings.jsx:162
#: src/components/status.jsx:94
msgid "Local"
msgstr "Local"

#: src/components/compose.jsx:1179
#: src/components/status.jsx:95
#: src/pages/settings.jsx:300
msgid "Unlisted"
msgstr "Unlisted"

#: src/components/compose.jsx:1182
#: src/components/status.jsx:96
#: src/pages/settings.jsx:303
msgid "Followers only"
msgstr "Followers only"

#: src/components/compose.jsx:1185
#: src/components/status.jsx:97
#: src/components/status.jsx:1904
msgid "Private mention"
msgstr "Private mention"

#: src/components/compose.jsx:1194
msgid "Post your reply"
msgstr "Post your reply"

#: src/components/compose.jsx:1196
msgid "Edit your post"
msgstr "Edit your post"

#: src/components/compose.jsx:1197
msgid "Bark here"
msgstr "Bark here"

#: src/components/compose.jsx:1275
msgid "Mark media as sensitive"
msgstr "Mark media as sensitive"

#: src/components/compose.jsx:1373
msgid "Add poll"
msgstr "Add poll"

#: src/components/compose.jsx:1395
msgid "Add custom emoji"
msgstr "Add custom emoji"

#: src/components/compose.jsx:1479
#: src/components/keyboard-shortcuts-help.jsx:143
#: src/components/status.jsx:895
#: src/components/status.jsx:1680
#: src/components/status.jsx:1681
#: src/components/status.jsx:2335
msgid "Reply"
msgstr "Reply"

#: src/components/compose.jsx:1481
msgid "Uwupdate"
msgstr "Uwupdate"

#: src/components/compose.jsx:1482
msgctxt "Submit button in composer"
msgid "Yip"
msgstr "Yip"

#: src/components/compose.jsx:1610
msgid "Downloading GIF…"
msgstr "Downloading GIF…"

#: src/components/compose.jsx:1638
msgid "Failed to download GIF"
msgstr "Failed to download GIF"

#: src/components/compose.jsx:1750
#: src/components/compose.jsx:1827
#: src/components/nav-menu.jsx:287
msgid "More…"
msgstr "More…"

#: src/components/compose.jsx:2232
msgid "Uploaded"
msgstr "Uploaded"

#: src/components/compose.jsx:2245
msgid "Image description"
msgstr "Image description"

#: src/components/compose.jsx:2246
msgid "Video description"
msgstr "Video description"

#: src/components/compose.jsx:2247
msgid "Audio description"
msgstr "Audio description"

#: src/components/compose.jsx:2283
#: src/components/compose.jsx:2303
msgid "File size too large. Uploading might encounter issues. Try reduce the file size from {0} to {1} or lower."
msgstr "File size too large. Uploading might encounter issues. Try reduce the file size from {0} to {1} or lower."

#: src/components/compose.jsx:2295
#: src/components/compose.jsx:2315
msgid "Dimension too large. Uploading might encounter issues. Try reduce dimension from {0}×{1}px to {2}×{3}px."
msgstr "Dimension too large. Uploading might encounter issues. Try reduce dimension from {0}×{1}px to {2}×{3}px."

#: src/components/compose.jsx:2323
msgid "Frame rate too high. Uploading might encounter issues."
msgstr "Frame rate too high. Uploading might encounter issues."

#: src/components/compose.jsx:2383
#: src/components/compose.jsx:2633
#: src/components/shortcuts-settings.jsx:723
#: src/pages/catchup.jsx:1074
#: src/pages/filters.jsx:412
msgid "Remove"
msgstr "Remove"

#: src/components/compose.jsx:2400
#: src/compose.jsx:83
msgid "Error"
msgstr "Error"

#: src/components/compose.jsx:2425
msgid "Edit image description"
msgstr "Edit image description"

#: src/components/compose.jsx:2426
msgid "Edit video description"
msgstr "Edit video description"

#: src/components/compose.jsx:2427
msgid "Edit audio description"
msgstr "Edit audio description"

#: src/components/compose.jsx:2472
#: src/components/compose.jsx:2521
msgid "Generating description. Please wait…"
msgstr "Generating description. Please wait…"

#: src/components/compose.jsx:2492
msgid "Failed to generate description: {0}"
msgstr "Failed to generate description: {0}"

#: src/components/compose.jsx:2493
msgid "Failed to generate description"
msgstr "Failed to generate description"

#: src/components/compose.jsx:2505
#: src/components/compose.jsx:2511
#: src/components/compose.jsx:2557
msgid "Generate description…"
msgstr "Generate description…"

#: src/components/compose.jsx:2544
msgid "Failed to generate description{0}"
msgstr "Failed to generate description{0}"

#: src/components/compose.jsx:2559
msgid "({0}) <0>— experimental</0>"
msgstr "({0}) <0>— experimental</0>"

#: src/components/compose.jsx:2578
msgid "Done"
msgstr "Done"

#: src/components/compose.jsx:2614
msgid "Choice {0}"
msgstr "Choice {0}"

#: src/components/compose.jsx:2661
msgid "Multiple choices"
msgstr "Multiple choices"

#: src/components/compose.jsx:2664
msgid "Duration"
msgstr "Duration"

#: src/components/compose.jsx:2695
msgid "Remove poll"
msgstr "Remove poll"

#: src/components/compose.jsx:2909
msgid "Search accounts"
msgstr "Search accounts"

#: src/components/compose.jsx:2950
#: src/components/shortcuts-settings.jsx:712
#: src/pages/list.jsx:360
msgid "Add"
msgstr "Add"

#: src/components/compose.jsx:2963
#: src/components/generic-accounts.jsx:227
msgid "Error loading accounts"
msgstr "Error loading accounts"

#: src/components/compose.jsx:3106
msgid "Custom emojis"
msgstr "Custom emojis"

#: src/components/compose.jsx:3126
msgid "Search emoji"
msgstr "Search emoji"

#: src/components/compose.jsx:3157
msgid "Error loading custom emojis"
msgstr "Error loading custom emojis"

#: src/components/compose.jsx:3168
msgid "Recently used"
msgstr "Recently used"

#: src/components/compose.jsx:3169
msgid "Others"
msgstr "Others"

#: src/components/compose.jsx:3207
msgid "{0} more…"
msgstr "{0} more…"

#: src/components/compose.jsx:3345
msgid "Search GIFs"
msgstr "Search GIFs"

#: src/components/compose.jsx:3360
msgid "Powered by GIPHY"
msgstr "Powered by GIPHY"

#: src/components/compose.jsx:3368
msgid "Type to search GIFs"
msgstr "Type to search GIFs"

#: src/components/compose.jsx:3466
#: src/components/media-modal.jsx:387
#: src/components/timeline.jsx:897
msgid "Previous"
msgstr "Previous"

#: src/components/compose.jsx:3484
#: src/components/media-modal.jsx:406
#: src/components/timeline.jsx:914
msgid "Next"
msgstr "Next"

#: src/components/compose.jsx:3501
msgid "Error loading GIFs"
msgstr "Error loading GIFs"

#: src/components/drafts.jsx:63
#: src/pages/settings.jsx:696
msgid "Unsent drafts"
msgstr "Unsent drafts"

#: src/components/drafts.jsx:68
msgid "Looks like you have unsent drafts. Let's continue where you left off."
msgstr "Looks like you have unsent drafts. Let's continue where you left off."

#: src/components/drafts.jsx:102
msgid "Delete this draft?"
msgstr "Delete this draft?"

#: src/components/drafts.jsx:117
msgid "Error deleting draft! Please try again."
msgstr "Error deleting draft! Please try again."

#: src/components/drafts.jsx:127
#: src/components/list-add-edit.jsx:183
#: src/components/status.jsx:1315
#: src/pages/filters.jsx:587
msgid "Delete…"
msgstr "Delete…"

#: src/components/drafts.jsx:146
msgid "Error fetching reply-to status!"
msgstr "Error fetching reply-to status!"

#: src/components/drafts.jsx:171
msgid "Delete all drafts?"
msgstr "Delete all drafts?"

#: src/components/drafts.jsx:189
msgid "Error deleting drafts! Please try again."
msgstr "Error deleting drafts! Please try again."

#: src/components/drafts.jsx:201
msgid "Delete all…"
msgstr "Delete all…"

#: src/components/drafts.jsx:209
msgid "No drafts found."
msgstr "No drafts found."

#: src/components/drafts.jsx:245
#: src/pages/catchup.jsx:1928
msgid "Poll"
msgstr "Poll"

#: src/components/drafts.jsx:248
#: src/pages/account-statuses.jsx:365
msgid "Media"
msgstr "Media"

#: src/components/embed-modal.jsx:22
msgid "Open in new window"
msgstr "Open in new window"

#: src/components/follow-request-buttons.jsx:42
#: src/pages/notifications.jsx:919
msgid "Accept"
msgstr "Accept"

#: src/components/follow-request-buttons.jsx:68
msgid "Reject"
msgstr "Reject"

#: src/components/follow-request-buttons.jsx:75
#: src/pages/notifications.jsx:1202
msgid "Accepted"
msgstr "Accepted"

#: src/components/follow-request-buttons.jsx:79
msgid "Rejected"
msgstr "Rejected"

#: src/components/generic-accounts.jsx:24
msgid "Nothing to show"
msgstr "Nothing to show"

#: src/components/generic-accounts.jsx:145
#: src/components/notification.jsx:437
#: src/pages/accounts.jsx:41
#: src/pages/search.jsx:227
#: src/pages/search.jsx:260
msgid "Accounts"
msgstr "Accounts"

#: src/components/generic-accounts.jsx:205
#: src/components/timeline.jsx:527
#: src/pages/list.jsx:294
#: src/pages/notifications.jsx:849
#: src/pages/search.jsx:454
#: src/pages/status.jsx:1289
msgid "Show more…"
msgstr "Show more…"

#: src/components/generic-accounts.jsx:210
#: src/components/timeline.jsx:532
#: src/pages/search.jsx:459
msgid "The end."
msgstr "The end."

#: src/components/keyboard-shortcuts-help.jsx:43
#: src/components/nav-menu.jsx:405
#: src/pages/catchup.jsx:1619
msgid "Keyboard shortcuts"
msgstr "Keyboard shortcuts"

#: src/components/keyboard-shortcuts-help.jsx:51
msgid "Keyboard shortcuts help"
msgstr "Keyboard shortcuts help"

#: src/components/keyboard-shortcuts-help.jsx:55
#: src/pages/catchup.jsx:1644
msgid "Next post"
msgstr "Next post"

#: src/components/keyboard-shortcuts-help.jsx:59
#: src/pages/catchup.jsx:1652
msgid "Previous post"
msgstr "Previous post"

#: src/components/keyboard-shortcuts-help.jsx:63
msgid "Skip carousel to next post"
msgstr "Skip carousel to next post"

#: src/components/keyboard-shortcuts-help.jsx:65
msgid "<0>Shift</0> + <1>j</1>"
msgstr "<0>Shift</0> + <1>j</1>"

#: src/components/keyboard-shortcuts-help.jsx:71
msgid "Skip carousel to previous post"
msgstr "Skip carousel to previous post"

#: src/components/keyboard-shortcuts-help.jsx:73
msgid "<0>Shift</0> + <1>k</1>"
msgstr "<0>Shift</0> + <1>k</1>"

#: src/components/keyboard-shortcuts-help.jsx:79
msgid "Load new posts"
msgstr "Load new posts"

#: src/components/keyboard-shortcuts-help.jsx:83
#: src/pages/catchup.jsx:1676
msgid "Open post details"
msgstr "Open post details"

#: src/components/keyboard-shortcuts-help.jsx:85
msgid "<0>Enter</0> or <1>o</1>"
msgstr "<0>Enter</0> or <1>o</1>"

#: src/components/keyboard-shortcuts-help.jsx:92
msgid "Expand content warning or<0/>toggle expanded/collapsed thread"
msgstr "Expand content warning or<0/>toggle expanded/collapsed thread"

#: src/components/keyboard-shortcuts-help.jsx:101
msgid "Close post or dialogs"
msgstr "Close post or dialogs"

#: src/components/keyboard-shortcuts-help.jsx:103
msgid "<0>Esc</0> or <1>Backspace</1>"
msgstr "<0>Esc</0> or <1>Backspace</1>"

#: src/components/keyboard-shortcuts-help.jsx:109
msgid "Focus column in multi-column mode"
msgstr "Focus column in multi-column mode"

#: src/components/keyboard-shortcuts-help.jsx:111
msgid "<0>1</0> to <1>9</1>"
msgstr "<0>1</0> to <1>9</1>"

#: src/components/keyboard-shortcuts-help.jsx:117
msgid "Compose new post"
msgstr "Compose new post"

#: src/components/keyboard-shortcuts-help.jsx:121
msgid "Compose new post (new window)"
msgstr "Compose new post (new window)"

#: src/components/keyboard-shortcuts-help.jsx:124
msgid "<0>Shift</0> + <1>c</1>"
msgstr "<0>Shift</0> + <1>c</1>"

#: src/components/keyboard-shortcuts-help.jsx:130
msgid "Send post"
msgstr "Send post"

#: src/components/keyboard-shortcuts-help.jsx:132
msgid "<0>Ctrl</0> + <1>Enter</1> or <2>⌘</2> + <3>Enter</3>"
msgstr "<0>Ctrl</0> + <1>Enter</1> or <2>⌘</2> + <3>Enter</3>"

#: src/components/keyboard-shortcuts-help.jsx:139
#: src/components/nav-menu.jsx:374
#: src/components/search-form.jsx:72
#: src/components/shortcuts-settings.jsx:52
#: src/components/shortcuts-settings.jsx:176
#: src/pages/search.jsx:39
#: src/pages/search.jsx:209
msgid "Search"
msgstr "Search"

#: src/components/keyboard-shortcuts-help.jsx:147
msgid "Reply (new window)"
msgstr "Reply (new window)"

#: src/components/keyboard-shortcuts-help.jsx:150
msgid "<0>Shift</0> + <1>r</1>"
msgstr "<0>Shift</0> + <1>r</1>"

#: src/components/keyboard-shortcuts-help.jsx:156
msgid "Like (favourite)"
msgstr "Like (favourite)"

#: src/components/keyboard-shortcuts-help.jsx:158
msgid "<0>l</0> or <1>f</1>"
msgstr "<0>l</0> or <1>f</1>"

#: src/components/keyboard-shortcuts-help.jsx:164
#: src/components/status.jsx:903
#: src/components/status.jsx:2362
#: src/components/status.jsx:2395
#: src/components/status.jsx:2396
msgid "Boost"
msgstr "Boost"

#: src/components/keyboard-shortcuts-help.jsx:166
msgid "<0>Shift</0> + <1>b</1>"
msgstr "<0>Shift</0> + <1>b</1>"

#: src/components/keyboard-shortcuts-help.jsx:172
#: src/components/status.jsx:988
#: src/components/status.jsx:2420
#: src/components/status.jsx:2421
msgid "Bookmark"
msgstr "Bookmark"

#: src/components/keyboard-shortcuts-help.jsx:176
msgid "Toggle Cloak mode"
msgstr "Toggle Cloak mode"

#: src/components/keyboard-shortcuts-help.jsx:178
msgid "<0>Shift</0> + <1>Alt</1> + <2>k</2>"
msgstr "<0>Shift</0> + <1>Alt</1> + <2>k</2>"

#: src/components/list-add-edit.jsx:37
msgid "Edit list"
msgstr "Edit list"

#: src/components/list-add-edit.jsx:93
msgid "Unable to edit list."
msgstr "Unable to edit list."

#: src/components/list-add-edit.jsx:94
msgid "Unable to create list."
msgstr "Unable to create list."

#: src/components/list-add-edit.jsx:122
msgid "Show replies to list members"
msgstr "Show replies to list members"

#: src/components/list-add-edit.jsx:125
msgid "Show replies to people I follow"
msgstr "Show replies to people I follow"

#: src/components/list-add-edit.jsx:128
msgid "Don't show replies"
msgstr "Don't show replies"

#: src/components/list-add-edit.jsx:141
msgid "Hide posts on this list from Home/Following"
msgstr "Hide posts on this list from Home/Following"

#: src/components/list-add-edit.jsx:147
#: src/pages/filters.jsx:554
msgid "Create"
msgstr "Create"

#: src/components/list-add-edit.jsx:154
msgid "Delete this list?"
msgstr "Delete this list?"

#: src/components/list-add-edit.jsx:173
msgid "Unable to delete list."
msgstr "Unable to delete list."

#: src/components/media-alt-modal.jsx:38
#: src/components/media.jsx:50
msgid "Media description"
msgstr "Media description"

#: src/components/media-alt-modal.jsx:57
#: src/components/status.jsx:1032
#: src/components/status.jsx:1059
#: src/components/translation-block.jsx:195
msgid "Translate"
msgstr "Translate"

#: src/components/media-alt-modal.jsx:68
#: src/components/status.jsx:1046
#: src/components/status.jsx:1073
msgid "Speak"
msgstr "Speak"

#: src/components/media-modal.jsx:294
msgid "Open original media in new window"
msgstr "Open original media in new window"

#: src/components/media-modal.jsx:298
msgid "Open original media"
msgstr "Open original media"

#: src/components/media-modal.jsx:314
msgid "Attempting to describe image. Please wait…"
msgstr "Attempting to describe image. Please wait…"

#: src/components/media-modal.jsx:329
msgid "Failed to describe image"
msgstr "Failed to describe image"

#: src/components/media-modal.jsx:339
msgid "Describe image…"
msgstr "Describe image…"

#: src/components/media-modal.jsx:362
msgid "View post"
msgstr "View post"

#: src/components/media-post.jsx:127
msgid "Sensitive media"
msgstr "Sensitive media"

#: src/components/media-post.jsx:132
msgid "Filtered: {filterTitleStr}"
msgstr "Filtered: {filterTitleStr}"

#: src/components/media-post.jsx:133
#: src/components/status.jsx:3510
#: src/components/status.jsx:3606
#: src/components/status.jsx:3684
#: src/components/timeline.jsx:981
#: src/pages/catchup.jsx:75
#: src/pages/catchup.jsx:1876
msgid "Filtered"
msgstr "Filtered"

#: src/components/modals.jsx:72
msgid "Post published. Check it out."
msgstr "Post published. Check it out."

#: src/components/modals.jsx:73
msgid "Reply posted. Check it out."
msgstr "Reply posted. Check it out."

#: src/components/modals.jsx:74
msgid "Post updated. Check it out."
msgstr "Post updated. Check it out."

#: src/components/nav-menu.jsx:126
msgid "Menu"
msgstr "Menu"

#: src/components/nav-menu.jsx:162
msgid "Reload page now to update?"
msgstr "Reload page now to update?"

#: src/components/nav-menu.jsx:174
msgid "New update available…"
msgstr "New update available…"

#: src/components/nav-menu.jsx:200
#: src/pages/catchup.jsx:871
msgid "Catch-up"
msgstr "Catch-up"

#: src/components/nav-menu.jsx:207
#: src/components/shortcuts-settings.jsx:58
#: src/components/shortcuts-settings.jsx:143
#: src/pages/home.jsx:224
#: src/pages/mentions.jsx:21
#: src/pages/mentions.jsx:207
#: src/pages/settings.jsx:1107
#: src/pages/trending.jsx:381
msgid "Mentions"
msgstr "Mentions"

#: src/components/nav-menu.jsx:214
#: src/components/shortcuts-settings.jsx:49
#: src/components/shortcuts-settings.jsx:149
#: src/pages/filters.jsx:24
#: src/pages/home.jsx:84
#: src/pages/home.jsx:184
#: src/pages/notifications.jsx:112
#: src/pages/notifications.jsx:525
msgid "Notifications"
msgstr "Notifications"

#: src/components/nav-menu.jsx:217
msgid "New"
msgstr "New"

#: src/components/nav-menu.jsx:228
msgid "Profile"
msgstr "Profile"

#: src/components/nav-menu.jsx:241
#: src/components/nav-menu.jsx:268
#: src/components/shortcuts-settings.jsx:50
#: src/components/shortcuts-settings.jsx:155
#: src/pages/list.jsx:127
#: src/pages/lists.jsx:16
#: src/pages/lists.jsx:50
msgid "Lists"
msgstr "Lists"

#: src/components/nav-menu.jsx:249
#: src/components/shortcuts.jsx:209
#: src/pages/list.jsx:134
msgid "All Lists"
msgstr "All Lists"

#: src/components/nav-menu.jsx:276
#: src/components/shortcuts-settings.jsx:54
#: src/components/shortcuts-settings.jsx:192
#: src/pages/bookmarks.jsx:11
#: src/pages/bookmarks.jsx:23
msgid "Bookmarks"
msgstr "Bookmarks"

#: src/components/nav-menu.jsx:296
#: src/components/shortcuts-settings.jsx:55
#: src/components/shortcuts-settings.jsx:198
#: src/pages/catchup.jsx:1446
#: src/pages/catchup.jsx:2062
#: src/pages/favourites.jsx:11
#: src/pages/favourites.jsx:23
#: src/pages/settings.jsx:1111
msgid "Likes"
msgstr "Likes"

#: src/components/nav-menu.jsx:302
#: src/pages/followed-hashtags.jsx:14
#: src/pages/followed-hashtags.jsx:44
msgid "Followed Hashtags"
msgstr "Followed Hashtags"

#: src/components/nav-menu.jsx:310
#: src/pages/account-statuses.jsx:331
#: src/pages/filters.jsx:54
#: src/pages/filters.jsx:93
#: src/pages/hashtag.jsx:339
msgid "Filters"
msgstr "Filters"

#: src/components/nav-menu.jsx:318
msgid "Muted users"
msgstr "Muted users"

#: src/components/nav-menu.jsx:326
msgid "Muted users…"
msgstr "Muted users…"

#: src/components/nav-menu.jsx:333
msgid "Blocked users"
msgstr "Blocked users"

#: src/components/nav-menu.jsx:341
msgid "Blocked users…"
msgstr "Blocked users…"

#: src/components/nav-menu.jsx:353
msgid "Accounts…"
msgstr "Accounts…"

#: src/components/nav-menu.jsx:363
#: src/pages/login.jsx:189
#: src/pages/status.jsx:792
#: src/pages/welcome.jsx:64
msgid "Log in"
msgstr "Log in"

#: src/components/nav-menu.jsx:380
#: src/components/shortcuts-settings.jsx:57
#: src/components/shortcuts-settings.jsx:169
#: src/pages/trending.jsx:441
msgid "Trending"
msgstr "Trending"

#: src/components/nav-menu.jsx:392
#: src/components/shortcuts-settings.jsx:162
msgid "Federated"
msgstr "Federated"

#: src/components/nav-menu.jsx:415
msgid "Shortcuts / Columns…"
msgstr "Shortcuts / Columns…"

#: src/components/nav-menu.jsx:425
#: src/components/nav-menu.jsx:439
msgid "Settings…"
msgstr "Settings…"

#: src/components/notification-service.jsx:160
msgid "Notification"
msgstr "Notification"

#: src/components/notification-service.jsx:166
msgid "This notification is from your other account."
msgstr "This notification is from your other account."

#: src/components/notification-service.jsx:195
msgid "View all notifications"
msgstr "View all notifications"

#: src/components/notification.jsx:69
msgid "{account} reacted to your post with {emojiObject}"
msgstr "{account} reacted to your post with {emojiObject}"

#: src/components/notification.jsx:76
msgid "{account} published a post."
msgstr "{account} published a post."

#: src/components/notification.jsx:84
msgid "{count, plural, =1 {{postsCount, plural, =1 {{postType, select, reply {{account} boosted your reply.} other {{account} boosted your post.}}} other {{account} boosted {postsCount} of your posts.}}} other {{postType, select, reply {<0><1>{0}</1> people</0> boosted your reply.} other {<2><3>{1}</3> people</2> boosted your post.}}}}"
msgstr "{count, plural, =1 {{postsCount, plural, =1 {{postType, select, reply {{account} boosted your reply.} other {{account} boosted your post.}}} other {{account} boosted {postsCount} of your posts.}}} other {{postType, select, reply {<0><1>{0}</1> people</0> boosted your reply.} other {<2><3>{1}</3> people</2> boosted your post.}}}}"

#: src/components/notification.jsx:127
msgid "{count, plural, =1 {{account} followed you.} other {<0><1>{0}</1> people</0> followed you.}}"
msgstr "{count, plural, =1 {{account} followed you.} other {<0><1>{0}</1> people</0> followed you.}}"

#: src/components/notification.jsx:141
msgid "{account} requested to follow you."
msgstr "{account} requested to follow you."

#: src/components/notification.jsx:150
msgid "{count, plural, =1 {{postsCount, plural, =1 {{postType, select, reply {{account} liked your reply.} other {{account} liked your post.}}} other {{account} liked {postsCount} of your posts.}}} other {{postType, select, reply {<0><1>{0}</1> people</0> liked your reply.} other {<2><3>{1}</3> people</2> liked your post.}}}}"
msgstr "{count, plural, =1 {{postsCount, plural, =1 {{postType, select, reply {{account} liked your reply.} other {{account} liked your post.}}} other {{account} liked {postsCount} of your posts.}}} other {{postType, select, reply {<0><1>{0}</1> people</0> liked your reply.} other {<2><3>{1}</3> people</2> liked your post.}}}}"

#: src/components/notification.jsx:192
msgid "A poll you have voted in or created has ended."
msgstr "A poll you have voted in or created has ended."

#: src/components/notification.jsx:193
msgid "A poll you have created has ended."
msgstr "A poll you have created has ended."

#: src/components/notification.jsx:194
msgid "A poll you have voted in has ended."
msgstr "A poll you have voted in has ended."

#: src/components/notification.jsx:195
msgid "A post you interacted with has been edited."
msgstr "A post you interacted with has been edited."

#: src/components/notification.jsx:203
msgid "{count, plural, =1 {{postsCount, plural, =1 {{postType, select, reply {{account} boosted & liked your reply.} other {{account} boosted & liked your post.}}} other {{account} boosted & liked {postsCount} of your posts.}}} other {{postType, select, reply {<0><1>{0}</1> people</0> boosted & liked your reply.} other {<2><3>{1}</3> people</2> boosted & liked your post.}}}}"
msgstr "{count, plural, =1 {{postsCount, plural, =1 {{postType, select, reply {{account} boosted & liked your reply.} other {{account} boosted & liked your post.}}} other {{account} boosted & liked {postsCount} of your posts.}}} other {{postType, select, reply {<0><1>{0}</1> people</0> boosted & liked your reply.} other {<2><3>{1}</3> people</2> boosted & liked your post.}}}}"

#: src/components/notification.jsx:245
msgid "{account} signed up."
msgstr "{account} signed up."

#: src/components/notification.jsx:247
msgid "{account} reported {targetAccount}"
msgstr "{account} reported {targetAccount}"

#: src/components/notification.jsx:252
msgid "Lost connections with <0>{name}</0>."
msgstr "Lost connections with <0>{name}</0>."

#: src/components/notification.jsx:258
msgid "Moderation warning"
msgstr "Moderation warning"

#: src/components/notification.jsx:268
msgid "An admin from <0>{from}</0> has suspended <1>{targetName}</1>, which means you can no longer receive updates from them or interact with them."
msgstr "An admin from <0>{from}</0> has suspended <1>{targetName}</1>, which means you can no longer receive updates from them or interact with them."

#: src/components/notification.jsx:274
msgid "An admin from <0>{from}</0> has blocked <1>{targetName}</1>. Affected followers: {followersCount}, followings: {followingCount}."
msgstr "An admin from <0>{from}</0> has blocked <1>{targetName}</1>. Affected followers: {followersCount}, followings: {followingCount}."

#: src/components/notification.jsx:280
msgid "You have blocked <0>{targetName}</0>. Removed followers: {followersCount}, followings: {followingCount}."
msgstr "You have blocked <0>{targetName}</0>. Removed followers: {followersCount}, followings: {followingCount}."

#: src/components/notification.jsx:288
msgid "Your account has received a moderation warning."
msgstr "Your account has received a moderation warning."

#: src/components/notification.jsx:289
msgid "Your account has been disabled."
msgstr "Your account has been disabled."

#: src/components/notification.jsx:290
msgid "Some of your posts have been marked as sensitive."
msgstr "Some of your posts have been marked as sensitive."

#: src/components/notification.jsx:291
msgid "Some of your posts have been deleted."
msgstr "Some of your posts have been deleted."

#: src/components/notification.jsx:292
msgid "Your posts will be marked as sensitive from now on."
msgstr "Your posts will be marked as sensitive from now on."

#: src/components/notification.jsx:293
msgid "Your account has been limited."
msgstr "Your account has been limited."

#: src/components/notification.jsx:294
msgid "Your account has been suspended."
msgstr "Your account has been suspended."

#: src/components/notification.jsx:368
msgid "[Unknown notification type: {type}]"
msgstr "[Unknown notification type: {type}]"

#: src/components/notification.jsx:433
#: src/components/status.jsx:1002
#: src/components/status.jsx:1012
msgid "Boosted/Liked by…"
msgstr "Boosted/Liked by…"

#: src/components/notification.jsx:434
msgid "Liked by…"
msgstr "Liked by…"

#: src/components/notification.jsx:435
msgid "Boosted by…"
msgstr "Boosted by…"

#: src/components/notification.jsx:436
msgid "Followed by…"
msgstr "Followed by…"

#: src/components/notification.jsx:497
#: src/components/notification.jsx:513
msgid "Learn more <0/>"
msgstr "Learn more <0/>"

#: src/components/notification.jsx:745
#: src/components/status.jsx:211
msgid "Read more →"
msgstr "Read more →"

#: src/components/poll.jsx:110
msgid "Voted"
msgstr "Voted"

#: src/components/poll.jsx:116
msgid "{optionVotesCount, plural, one {# vote} other {# votes}}"
msgstr "{optionVotesCount, plural, one {# vote} other {# votes}}"

#: src/components/poll.jsx:136
#: src/components/poll.jsx:219
#: src/components/poll.jsx:223
msgid "Hide results"
msgstr "Hide results"

#: src/components/poll.jsx:185
msgid "Vote"
msgstr "Vote"

#: src/components/poll.jsx:205
#: src/components/poll.jsx:207
#: src/pages/status.jsx:1158
#: src/pages/status.jsx:1181
msgid "Refresh"
msgstr "Refresh"

#: src/components/poll.jsx:219
#: src/components/poll.jsx:223
msgid "Show results"
msgstr "Show results"

#: src/components/poll.jsx:228
msgid "{votesCount, plural, one {<0>{0}</0> vote} other {<1>{1}</1> votes}}"
msgstr "{votesCount, plural, one {<0>{0}</0> vote} other {<1>{1}</1> votes}}"

#: src/components/poll.jsx:245
msgid "{votersCount, plural, one {<0>{0}</0> voter} other {<1>{1}</1> voters}}"
msgstr "{votersCount, plural, one {<0>{0}</0> voter} other {<1>{1}</1> voters}}"

#: src/components/poll.jsx:265
msgid "Ended <0/>"
msgstr "Ended <0/>"

#: src/components/poll.jsx:269
msgid "Ended"
msgstr "Ended"

#: src/components/poll.jsx:272
msgid "Ending <0/>"
msgstr "Ending <0/>"

#: src/components/poll.jsx:276
msgid "Ending"
msgstr "Ending"

#. Relative time in seconds, as short as possible
#: src/components/relative-time.jsx:57
msgid "{0}s"
msgstr "{0}s"

#. Relative time in minutes, as short as possible
#: src/components/relative-time.jsx:62
msgid "{0}m"
msgstr "{0}m"

#. Relative time in hours, as short as possible
#: src/components/relative-time.jsx:67
msgid "{0}h"
msgstr "{0}h"

#: src/components/report-modal.jsx:29
msgid "Spam"
msgstr "Spam"

#: src/components/report-modal.jsx:30
msgid "Malicious links, fake engagement, or repetitive replies"
msgstr "Malicious links, fake engagement, or repetitive replies"

#: src/components/report-modal.jsx:33
msgid "Illegal"
msgstr "Illegal"

#: src/components/report-modal.jsx:34
msgid "Violates the law of your or the server's country"
msgstr "Violates the law of your or the server's country"

#: src/components/report-modal.jsx:37
msgid "Server rule violation"
msgstr "Server rule violation"

#: src/components/report-modal.jsx:38
msgid "Breaks specific server rules"
msgstr "Breaks specific server rules"

#: src/components/report-modal.jsx:39
msgid "Violation"
msgstr "Violation"

#: src/components/report-modal.jsx:42
msgid "Other"
msgstr "Other"

#: src/components/report-modal.jsx:43
msgid "Issue doesn't fit other categories"
msgstr "Issue doesn't fit other categories"

#: src/components/report-modal.jsx:68
msgid "Report Post"
msgstr "Report Post"

#: src/components/report-modal.jsx:68
msgid "Report @{username}"
msgstr "Report @{username}"

#: src/components/report-modal.jsx:104
msgid "Pending review"
msgstr "Pending review"

#: src/components/report-modal.jsx:146
msgid "Post reported"
msgstr "Post reported"

#: src/components/report-modal.jsx:146
msgid "Profile reported"
msgstr "Profile reported"

#: src/components/report-modal.jsx:154
msgid "Unable to report post"
msgstr "Unable to report post"

#: src/components/report-modal.jsx:155
msgid "Unable to report profile"
msgstr "Unable to report profile"

#: src/components/report-modal.jsx:163
msgid "What's the issue with this post?"
msgstr "What's the issue with this post?"

#: src/components/report-modal.jsx:164
msgid "What's the issue with this profile?"
msgstr "What's the issue with this profile?"

#: src/components/report-modal.jsx:233
msgid "Additional info"
msgstr "Additional info"

#: src/components/report-modal.jsx:256
msgid "Forward to <0>{domain}</0>"
msgstr "Forward to <0>{domain}</0>"

#: src/components/report-modal.jsx:266
msgid "Send Report"
msgstr "Send Report"

#: src/components/report-modal.jsx:275
msgid "Muted {username}"
msgstr "Muted {username}"

#: src/components/report-modal.jsx:278
msgid "Unable to mute {username}"
msgstr "Unable to mute {username}"

#: src/components/report-modal.jsx:283
msgid "Send Report <0>+ Mute profile</0>"
msgstr "Send Report <0>+ Mute profile</0>"

#: src/components/report-modal.jsx:294
msgid "Blocked {username}"
msgstr "Blocked {username}"

#: src/components/report-modal.jsx:297
msgid "Unable to block {username}"
msgstr "Unable to block {username}"

#: src/components/report-modal.jsx:302
msgid "Send Report <0>+ Block profile</0>"
msgstr "Send Report <0>+ Block profile</0>"

#: src/components/search-form.jsx:202
msgid "{query} <0>‒ accounts, hashtags & posts</0>"
msgstr "{query} <0>‒ accounts, hashtags & posts</0>"

#: src/components/search-form.jsx:215
msgid "Posts with <0>{query}</0>"
msgstr "Posts with <0>{query}</0>"

#: src/components/search-form.jsx:227
msgid "Posts tagged with <0>#{0}</0>"
msgstr "Posts tagged with <0>#{0}</0>"

#: src/components/search-form.jsx:241
msgid "Look up <0>{query}</0>"
msgstr "Look up <0>{query}</0>"

#: src/components/search-form.jsx:252
msgid "Accounts with <0>{query}</0>"
msgstr "Accounts with <0>{query}</0>"

#: src/components/shortcuts-settings.jsx:48
msgid "Home / Following"
msgstr "Home / Following"

#: src/components/shortcuts-settings.jsx:51
msgid "Public (Local / Federated)"
msgstr "Public (Local / Federated)"

#: src/components/shortcuts-settings.jsx:53
msgid "Account"
msgstr "Account"

#: src/components/shortcuts-settings.jsx:56
msgid "Hashtag"
msgstr "Hashtag"

#: src/components/shortcuts-settings.jsx:63
msgid "List ID"
msgstr "List ID"

#: src/components/shortcuts-settings.jsx:70
msgid "Local only"
msgstr "Local only"

#: src/components/shortcuts-settings.jsx:75
#: src/components/shortcuts-settings.jsx:84
#: src/components/shortcuts-settings.jsx:122
#: src/pages/login.jsx:193
msgid "Instance"
msgstr "Instance"

#: src/components/shortcuts-settings.jsx:78
#: src/components/shortcuts-settings.jsx:87
#: src/components/shortcuts-settings.jsx:125
msgid "Optional, e.g. mastodon.social"
msgstr "Optional, e.g. mastodon.social"

#: src/components/shortcuts-settings.jsx:93
msgid "Search term"
msgstr "Search term"

#: src/components/shortcuts-settings.jsx:96
msgid "Optional, unless for multi-column mode"
msgstr "Optional, unless for multi-column mode"

#: src/components/shortcuts-settings.jsx:113
msgid "e.g. PixelArt (Max 5, space-separated)"
msgstr "e.g. PixelArt (Max 5, space-separated)"

#: src/components/shortcuts-settings.jsx:117
#: src/pages/hashtag.jsx:355
msgid "Media only"
msgstr "Media only"

#: src/components/shortcuts-settings.jsx:232
#: src/components/shortcuts.jsx:186
msgid "Shortcuts"
msgstr "Shortcuts"

#: src/components/shortcuts-settings.jsx:240
msgid "beta"
msgstr "beta"

#: src/components/shortcuts-settings.jsx:246
msgid "Specify a list of shortcuts that'll appear as:"
msgstr "Specify a list of shortcuts that'll appear as:"

#: src/components/shortcuts-settings.jsx:252
msgid "Floating button"
msgstr "Floating button"

#: src/components/shortcuts-settings.jsx:257
msgid "Tab/Menu bar"
msgstr "Tab/Menu bar"

#: src/components/shortcuts-settings.jsx:262
msgid "Multi-column"
msgstr "Multi-column"

#: src/components/shortcuts-settings.jsx:329
msgid "Not available in current view mode"
msgstr "Not available in current view mode"

#: src/components/shortcuts-settings.jsx:348
msgid "Move up"
msgstr "Move up"

#: src/components/shortcuts-settings.jsx:364
msgid "Move down"
msgstr "Move down"

#: src/components/shortcuts-settings.jsx:376
#: src/components/status.jsx:1280
#: src/pages/list.jsx:171
msgid "Edit"
msgstr "Edit"

#: src/components/shortcuts-settings.jsx:397
msgid "Add more than one shortcut/column to make this work."
msgstr "Add more than one shortcut/column to make this work."

#: src/components/shortcuts-settings.jsx:408
msgid "No columns yet. Tap on the Add column button."
msgstr "No columns yet. Tap on the Add column button."

#: src/components/shortcuts-settings.jsx:409
msgid "No shortcuts yet. Tap on the Add shortcut button."
msgstr "No shortcuts yet. Tap on the Add shortcut button."

#: src/components/shortcuts-settings.jsx:412
msgid "Not sure what to add?<0/>Try adding <1>Home / Following and Notifications</1> first."
msgstr "Not sure what to add?<0/>Try adding <1>Home / Following and Notifications</1> first."

#: src/components/shortcuts-settings.jsx:440
msgid "Max {SHORTCUTS_LIMIT} columns"
msgstr "Max {SHORTCUTS_LIMIT} columns"

#: src/components/shortcuts-settings.jsx:441
msgid "Max {SHORTCUTS_LIMIT} shortcuts"
msgstr "Max {SHORTCUTS_LIMIT} shortcuts"

#: src/components/shortcuts-settings.jsx:455
msgid "Import/export"
msgstr "Import/export"

#: src/components/shortcuts-settings.jsx:465
msgid "Add column…"
msgstr "Add column…"

#: src/components/shortcuts-settings.jsx:466
msgid "Add shortcut…"
msgstr "Add shortcut…"

#: src/components/shortcuts-settings.jsx:513
msgid "Specific list is optional. For multi-column mode, list is required, else the column will not be shown."
msgstr "Specific list is optional. For multi-column mode, list is required, else the column will not be shown."

#: src/components/shortcuts-settings.jsx:514
msgid "For multi-column mode, search term is required, else the column will not be shown."
msgstr "For multi-column mode, search term is required, else the column will not be shown."

#: src/components/shortcuts-settings.jsx:515
msgid "Multiple hashtags are supported. Space-separated."
msgstr "Multiple hashtags are supported. Space-separated."

#: src/components/shortcuts-settings.jsx:584
msgid "Edit shortcut"
msgstr "Edit shortcut"

#: src/components/shortcuts-settings.jsx:584
msgid "Add shortcut"
msgstr "Add shortcut"

#: src/components/shortcuts-settings.jsx:620
msgid "Timeline"
msgstr "Timeline"

#: src/components/shortcuts-settings.jsx:646
msgid "List"
msgstr "List"

#: src/components/shortcuts-settings.jsx:785
msgid "Import/Export <0>Shortcuts</0>"
msgstr "Import/Export <0>Shortcuts</0>"

#: src/components/shortcuts-settings.jsx:795
msgid "Import"
msgstr "Import"

#: src/components/shortcuts-settings.jsx:803
msgid "Paste shortcuts here"
msgstr "Paste shortcuts here"

#: src/components/shortcuts-settings.jsx:819
msgid "Downloading saved shortcuts from instance server…"
msgstr "Downloading saved shortcuts from instance server…"

#: src/components/shortcuts-settings.jsx:848
msgid "Unable to download shortcuts"
msgstr "Unable to download shortcuts"

#: src/components/shortcuts-settings.jsx:851
msgid "Download shortcuts from instance server"
msgstr "Download shortcuts from instance server"

#: src/components/shortcuts-settings.jsx:909
msgid "* Exists in current shortcuts"
msgstr "* Exists in current shortcuts"

#: src/components/shortcuts-settings.jsx:914
msgid "List may not work if it's from a different account."
msgstr "List may not work if it's from a different account."

#: src/components/shortcuts-settings.jsx:924
msgid "Invalid settings format"
msgstr "Invalid settings format"

#: src/components/shortcuts-settings.jsx:932
msgid "Append to current shortcuts?"
msgstr "Append to current shortcuts?"

#: src/components/shortcuts-settings.jsx:935
msgid "Only shortcuts that don’t exist in current shortcuts will be appended."
msgstr "Only shortcuts that don’t exist in current shortcuts will be appended."

#: src/components/shortcuts-settings.jsx:957
msgid "No new shortcuts to import"
msgstr "No new shortcuts to import"

#: src/components/shortcuts-settings.jsx:972
msgid "Shortcuts imported. Exceeded max {SHORTCUTS_LIMIT}, so the rest are not imported."
msgstr "Shortcuts imported. Exceeded max {SHORTCUTS_LIMIT}, so the rest are not imported."

#: src/components/shortcuts-settings.jsx:973
#: src/components/shortcuts-settings.jsx:997
msgid "Shortcuts imported"
msgstr "Shortcuts imported"

#: src/components/shortcuts-settings.jsx:983
msgid "Import & append…"
msgstr "Import & append…"

#: src/components/shortcuts-settings.jsx:991
msgid "Override current shortcuts?"
msgstr "Override current shortcuts?"

#: src/components/shortcuts-settings.jsx:992
msgid "Import shortcuts?"
msgstr "Import shortcuts?"

#: src/components/shortcuts-settings.jsx:1006
msgid "or override…"
msgstr "or override…"

#: src/components/shortcuts-settings.jsx:1006
msgid "Import…"
msgstr "Import…"

#: src/components/shortcuts-settings.jsx:1015
msgid "Export"
msgstr "Export"

#: src/components/shortcuts-settings.jsx:1030
msgid "Shortcuts copied"
msgstr "Shortcuts copied"

#: src/components/shortcuts-settings.jsx:1033
msgid "Unable to copy shortcuts"
msgstr "Unable to copy shortcuts"

#: src/components/shortcuts-settings.jsx:1047
msgid "Shortcut settings copied"
msgstr "Shortcut settings copied"

#: src/components/shortcuts-settings.jsx:1050
msgid "Unable to copy shortcut settings"
msgstr "Unable to copy shortcut settings"

#: src/components/shortcuts-settings.jsx:1080
msgid "Share"
msgstr "Share"

#: src/components/shortcuts-settings.jsx:1119
msgid "Saving shortcuts to instance server…"
msgstr "Saving shortcuts to instance server…"

#: src/components/shortcuts-settings.jsx:1126
msgid "Shortcuts saved"
msgstr "Shortcuts saved"

#: src/components/shortcuts-settings.jsx:1131
msgid "Unable to save shortcuts"
msgstr "Unable to save shortcuts"

#: src/components/shortcuts-settings.jsx:1134
msgid "Sync to instance server"
msgstr "Sync to instance server"

#: src/components/shortcuts-settings.jsx:1142
msgid "{0, plural, one {# character} other {# characters}}"
msgstr "{0, plural, one {# character} other {# characters}}"

#: src/components/shortcuts-settings.jsx:1154
msgid "Raw Shortcuts JSON"
msgstr "Raw Shortcuts JSON"

#: src/components/shortcuts-settings.jsx:1167
msgid "Import/export settings from/to instance server (Very experimental)"
msgstr "Import/export settings from/to instance server (Very experimental)"

#: src/components/status.jsx:504
msgid "<0/> <1>boosted</1>"
msgstr "<0/> <1>boosted</1>"

#: src/components/status.jsx:603
msgid "Sorry, your current logged-in instance can't interact with this post from another instance."
msgstr "Sorry, your current logged-in instance can't interact with this post from another instance."

#: src/components/status.jsx:756
msgid "Unliked @{0}'s post"
msgstr "Unliked @{0}'s post"

#: src/components/status.jsx:757
msgid "Liked @{0}'s post"
msgstr "Liked @{0}'s post"

#: src/components/status.jsx:796
msgid "Unbookmarked @{0}'s post"
msgstr "Unbookmarked @{0}'s post"

#: src/components/status.jsx:797
msgid "Bookmarked @{0}'s post"
msgstr "Bookmarked @{0}'s post"

#: src/components/status.jsx:903
#: src/components/status.jsx:965
#: src/components/status.jsx:2362
#: src/components/status.jsx:2395
msgid "Unboost"
msgstr "Unboost"

#: src/components/status.jsx:919
#: src/components/status.jsx:2378
msgid "Quote"
msgstr "Quote"

#: src/components/status.jsx:927
#: src/components/status.jsx:2387
msgid "Some media have no descriptions."
msgstr "Some media have no descriptions."

#: src/components/status.jsx:934
msgid "Old post (<0>{0}</0>)"
msgstr "Old post (<0>{0}</0>)"

#: src/components/status.jsx:953
#: src/components/status.jsx:1405
msgid "Unboosted @{0}'s post"
msgstr "Unboosted @{0}'s post"

#: src/components/status.jsx:954
#: src/components/status.jsx:1406
msgid "Boosted @{0}'s post"
msgstr "Boosted @{0}'s post"

#: src/components/status.jsx:966
msgid "Boost…"
msgstr "Boost…"

#: src/components/status.jsx:978
#: src/components/status.jsx:1690
#: src/components/status.jsx:2408
msgid "Unlike"
msgstr "Unlike"

#: src/components/status.jsx:979
#: src/components/status.jsx:1690
#: src/components/status.jsx:1691
#: src/components/status.jsx:2408
#: src/components/status.jsx:2409
msgid "Like"
msgstr "Like"

#: src/components/status.jsx:988
#: src/components/status.jsx:2420
msgid "Unbookmark"
msgstr "Unbookmark"

#: src/components/status.jsx:1096
msgid "View post by <0>@{0}</0>"
msgstr "View post by <0>@{0}</0>"

#: src/components/status.jsx:1117
msgid "Show Edit History"
msgstr "Show Edit History"

#: src/components/status.jsx:1120
msgid "Edited: {editedDateText}"
msgstr "Edited: {editedDateText}"

#: src/components/status.jsx:1187
#: src/components/status.jsx:3187
msgid "Embed post"
msgstr "Embed post"

#: src/components/status.jsx:1201
msgid "Conversation unmuted"
msgstr "Conversation unmuted"

#: src/components/status.jsx:1201
msgid "Conversation muted"
msgstr "Conversation muted"

#: src/components/status.jsx:1207
msgid "Unable to unmute conversation"
msgstr "Unable to unmute conversation"

#: src/components/status.jsx:1208
msgid "Unable to mute conversation"
msgstr "Unable to mute conversation"

#: src/components/status.jsx:1217
msgid "Unmute conversation"
msgstr "Unmute conversation"

#: src/components/status.jsx:1224
msgid "Mute conversation"
msgstr "Mute conversation"

#: src/components/status.jsx:1240
msgid "Post unpinned from profile"
msgstr "Post unpinned from profile"

#: src/components/status.jsx:1241
msgid "Post pinned to profile"
msgstr "Post pinned to profile"

#: src/components/status.jsx:1246
msgid "Unable to unpin post"
msgstr "Unable to unpin post"

#: src/components/status.jsx:1246
msgid "Unable to pin post"
msgstr "Unable to pin post"

#: src/components/status.jsx:1255
msgid "Unpin from profile"
msgstr "Unpin from profile"

#: src/components/status.jsx:1262
msgid "Pin to profile"
msgstr "Pin to profile"

#: src/components/status.jsx:1291
msgid "Delete this post?"
msgstr "Delete this post?"

#: src/components/status.jsx:1304
msgid "Post deleted"
msgstr "Post deleted"

#: src/components/status.jsx:1307
msgid "Unable to delete post"
msgstr "Unable to delete post"

#: src/components/status.jsx:1335
msgid "Report post…"
msgstr "Report post…"

#: src/components/status.jsx:1691
#: src/components/status.jsx:1727
#: src/components/status.jsx:2409
msgid "Liked"
msgstr "Liked"

#: src/components/status.jsx:1724
#: src/components/status.jsx:2396
msgid "Boosted"
msgstr "Boosted"

#: src/components/status.jsx:1734
#: src/components/status.jsx:2421
msgid "Bookmarked"
msgstr "Bookmarked"

#: src/components/status.jsx:1738
msgid "Pinned"
msgstr "Pinned"

#: src/components/status.jsx:1783
#: src/components/status.jsx:2238
msgid "Deleted"
msgstr "Deleted"

#: src/components/status.jsx:1824
msgid "{repliesCount, plural, one {# reply} other {# replies}}"
msgstr "{repliesCount, plural, one {# reply} other {# replies}}"

#: src/components/status.jsx:1913
msgid "Thread{0}"
msgstr "Thread{0}"

#: src/components/status.jsx:1989
#: src/components/status.jsx:2051
#: src/components/status.jsx:2136
msgid "Show less"
msgstr "Show less"

#: src/components/status.jsx:1989
#: src/components/status.jsx:2051
msgid "Show content"
msgstr "Show content"

#: src/components/status.jsx:2136
msgid "Show media"
msgstr "Show media"

#: src/components/status.jsx:2259
msgid "Edited"
msgstr "Edited"

#: src/components/status.jsx:2336
msgid "Comments"
msgstr "Comments"

#. More from [Author]
#: src/components/status.jsx:2649
msgid "More from <0/>"
msgstr "More from <0/>"

#: src/components/status.jsx:2948
msgid "Edit History"
msgstr "Edit History"

#: src/components/status.jsx:2952
msgid "Failed to load history"
msgstr "Failed to load history"

#: src/components/status.jsx:2957
msgid "Loading…"
msgstr "Loading…"

#: src/components/status.jsx:3192
msgid "HTML Code"
msgstr "HTML Code"

#: src/components/status.jsx:3209
msgid "HTML code copied"
msgstr "HTML code copied"

#: src/components/status.jsx:3212
msgid "Unable to copy HTML code"
msgstr "Unable to copy HTML code"

#: src/components/status.jsx:3224
msgid "Media attachments:"
msgstr "Media attachments:"

#: src/components/status.jsx:3246
msgid "Account Emojis:"
msgstr "Account Emojis:"

#: src/components/status.jsx:3277
#: src/components/status.jsx:3322
msgid "static URL"
msgstr "static URL"

#: src/components/status.jsx:3291
msgid "Emojis:"
msgstr "Emojis:"

#: src/components/status.jsx:3336
msgid "Notes:"
msgstr "Notes:"

#: src/components/status.jsx:3340
msgid "This is static, unstyled and scriptless. You may need to apply your own styles and edit as needed."
msgstr "This is static, unstyled and scriptless. You may need to apply your own styles and edit as needed."

#: src/components/status.jsx:3346
msgid "Polls are not interactive, becomes a list with vote counts."
msgstr "Polls are not interactive, becomes a list with vote counts."

#: src/components/status.jsx:3351
msgid "Media attachments can be images, videos, audios or any file types."
msgstr "Media attachments can be images, videos, audios or any file types."

#: src/components/status.jsx:3357
msgid "Post could be edited or deleted later."
msgstr "Post could be edited or deleted later."

#: src/components/status.jsx:3363
msgid "Preview"
msgstr "Preview"

#: src/components/status.jsx:3372
msgid "Note: This preview is lightly styled."
msgstr "Note: This preview is lightly styled."

#. [Name] [Visibility icon] boosted
#: src/components/status.jsx:3614
msgid "<0/> <1/> boosted"
msgstr "<0/> <1/> boosted"

#: src/components/timeline.jsx:461
#: src/pages/settings.jsx:1135
msgid "New posts"
msgstr "New posts"

#: src/components/timeline.jsx:562
#: src/pages/home.jsx:213
#: src/pages/notifications.jsx:825
#: src/pages/status.jsx:945
#: src/pages/status.jsx:1318
msgid "Try again"
msgstr "Try again"

#: src/components/timeline.jsx:596
msgid "{0, plural, one {# Boost} other {# Boosts}}"
msgstr "{0, plural, one {# Boost} other {# Boosts}}"

#: src/components/timeline.jsx:601
msgid "Pinned posts"
msgstr "Pinned posts"

#: src/components/timeline.jsx:954
#: src/components/timeline.jsx:961
#: src/pages/catchup.jsx:1893
msgid "Thread"
msgstr "Thread"

#: src/components/timeline.jsx:976
msgid "<0>Filtered</0>: <1>{0}</1>"
msgstr "<0>Filtered</0>: <1>{0}</1>"

#: src/components/translation-block.jsx:152
msgid "Auto-translated from {sourceLangText}"
msgstr "Auto-translated from {sourceLangText}"

#: src/components/translation-block.jsx:190
msgid "Translating…"
msgstr "Translating…"

#: src/components/translation-block.jsx:193
msgid "Translate from {sourceLangText} (auto-detected)"
msgstr "Translate from {sourceLangText} (auto-detected)"

#: src/components/translation-block.jsx:194
msgid "Translate from {sourceLangText}"
msgstr "Translate from {sourceLangText}"

#: src/components/translation-block.jsx:222
msgid "Auto ({0})"
msgstr "Auto ({0})"

#: src/components/translation-block.jsx:235
msgid "Failed to translate"
msgstr "Failed to translate"

#: src/compose.jsx:32
msgid "Editing source status"
msgstr "Editing source status"

#: src/compose.jsx:34
msgid "Replying to @{0}"
msgstr "Replying to @{0}"

#: src/compose.jsx:62
msgid "You may close this page now."
msgstr "You may close this page now."

#: src/compose.jsx:70
msgid "Close window"
msgstr "Close window"

#: src/compose.jsx:86
msgid "Login required."
msgstr "Login required."

#: src/compose.jsx:90
#: src/pages/http-route.jsx:91
#: src/pages/login.jsx:270
msgid "Go home"
msgstr "Go home"

#: src/pages/account-statuses.jsx:233
msgid "Account posts"
msgstr "Account posts"

#: src/pages/account-statuses.jsx:240
msgid "{accountDisplay} (+ Replies)"
msgstr "{accountDisplay} (+ Replies)"

#: src/pages/account-statuses.jsx:242
msgid "{accountDisplay} (- Boosts)"
msgstr "{accountDisplay} (- Boosts)"

#: src/pages/account-statuses.jsx:244
msgid "{accountDisplay} (#{tagged})"
msgstr "{accountDisplay} (#{tagged})"

#: src/pages/account-statuses.jsx:246
msgid "{accountDisplay} (Media)"
msgstr "{accountDisplay} (Media)"

#: src/pages/account-statuses.jsx:252
msgid "{accountDisplay} ({monthYear})"
msgstr "{accountDisplay} ({monthYear})"

#: src/pages/account-statuses.jsx:321
msgid "Clear filters"
msgstr "Clear filters"

#: src/pages/account-statuses.jsx:324
msgid "Clear"
msgstr "Clear"

#: src/pages/account-statuses.jsx:338
msgid "Showing post with replies"
msgstr "Showing post with replies"

#: src/pages/account-statuses.jsx:343
msgid "+ Replies"
msgstr "+ Replies"

#: src/pages/account-statuses.jsx:349
msgid "Showing posts without boosts"
msgstr "Showing posts without boosts"

#: src/pages/account-statuses.jsx:354
msgid "- Boosts"
msgstr "- Boosts"

#: src/pages/account-statuses.jsx:360
msgid "Showing posts with media"
msgstr "Showing posts with media"

#: src/pages/account-statuses.jsx:377
msgid "Showing posts tagged with #{0}"
msgstr "Showing posts tagged with #{0}"

#: src/pages/account-statuses.jsx:416
msgid "Showing posts in {0}"
msgstr "Showing posts in {0}"

#: src/pages/account-statuses.jsx:505
msgid "Nothing to see here yet."
msgstr "Nothing to see here yet."

#: src/pages/account-statuses.jsx:506
#: src/pages/public.jsx:97
#: src/pages/trending.jsx:449
msgid "Unable to load posts"
msgstr "Unable to load posts"

#: src/pages/account-statuses.jsx:548
#: src/pages/account-statuses.jsx:578
msgid "Unable to fetch account info"
msgstr "Unable to fetch account info"

#: src/pages/account-statuses.jsx:555
msgid "Switch to account's instance {0}"
msgstr "Switch to account's instance {0}"

#: src/pages/account-statuses.jsx:585
msgid "Switch to my instance (<0>{currentInstance}</0>)"
msgstr "Switch to my instance (<0>{currentInstance}</0>)"

#: src/pages/account-statuses.jsx:647
msgid "Month"
msgstr "Month"

#: src/pages/accounts.jsx:55
msgid "Current"
msgstr "Current"

#: src/pages/accounts.jsx:101
msgid "Default"
msgstr "Default"

#: src/pages/accounts.jsx:123
msgid "Switch to this account"
msgstr "Switch to this account"

#: src/pages/accounts.jsx:132
msgid "Switch in new tab/window"
msgstr "Switch in new tab/window"

#: src/pages/accounts.jsx:146
msgid "View profile…"
msgstr "View profile…"

#: src/pages/accounts.jsx:163
msgid "Set as default"
msgstr "Set as default"

#: src/pages/accounts.jsx:173
msgid "Log out <0>@{0}</0>?"
msgstr "Log out <0>@{0}</0>?"

#: src/pages/accounts.jsx:196
msgid "Log out…"
msgstr "Log out…"

#: src/pages/accounts.jsx:209
msgid "Add an existing account"
msgstr "Add an existing account"

#: src/pages/accounts.jsx:216
msgid "Note: <0>Default</0> account will always be used for first load. Switched accounts will persist during the session."
msgstr "Note: <0>Default</0> account will always be used for first load. Switched accounts will persist during the session."

#: src/pages/bookmarks.jsx:25
msgid "No bookmarks yet. Go bookmark something!"
msgstr "No bookmarks yet. Go bookmark something!"

#: src/pages/bookmarks.jsx:26
msgid "Unable to load bookmarks."
msgstr "Unable to load bookmarks."

#: src/pages/catchup.jsx:54
msgid "last 1 hour"
msgstr "last 1 hour"

#: src/pages/catchup.jsx:55
msgid "last 2 hours"
msgstr "last 2 hours"

#: src/pages/catchup.jsx:56
msgid "last 3 hours"
msgstr "last 3 hours"

#: src/pages/catchup.jsx:57
msgid "last 4 hours"
msgstr "last 4 hours"

#: src/pages/catchup.jsx:58
msgid "last 5 hours"
msgstr "last 5 hours"

#: src/pages/catchup.jsx:59
msgid "last 6 hours"
msgstr "last 6 hours"

#: src/pages/catchup.jsx:60
msgid "last 7 hours"
msgstr "last 7 hours"

#: src/pages/catchup.jsx:61
msgid "last 8 hours"
msgstr "last 8 hours"

#: src/pages/catchup.jsx:62
msgid "last 9 hours"
msgstr "last 9 hours"

#: src/pages/catchup.jsx:63
msgid "last 10 hours"
msgstr "last 10 hours"

#: src/pages/catchup.jsx:64
msgid "last 11 hours"
msgstr "last 11 hours"

#: src/pages/catchup.jsx:65
msgid "last 12 hours"
msgstr "last 12 hours"

#: src/pages/catchup.jsx:66
msgid "beyond 12 hours"
msgstr "beyond 12 hours"

#: src/pages/catchup.jsx:73
msgid "Followed tags"
msgstr "Followed tags"

#: src/pages/catchup.jsx:74
msgid "Groups"
msgstr "Groups"

#: src/pages/catchup.jsx:596
msgid "Showing {selectedFilterCategory, select, all {all posts} original {original posts} replies {replies} boosts {boosts} followedTags {followed tags} groups {groups} filtered {filtered posts}}, {sortBy, select, createdAt {{sortOrder, select, asc {oldest} desc {latest}}} reblogsCount {{sortOrder, select, asc {fewest boosts} desc {most boosts}}} favouritesCount {{sortOrder, select, asc {fewest likes} desc {most likes}}} repliesCount {{sortOrder, select, asc {fewest replies} desc {most replies}}} density {{sortOrder, select, asc {least dense} desc {most dense}}}} first{groupBy, select, account {, grouped by authors} other {}}"
msgstr "Showing {selectedFilterCategory, select, all {all posts} original {original posts} replies {replies} boosts {boosts} followedTags {followed tags} groups {groups} filtered {filtered posts}}, {sortBy, select, createdAt {{sortOrder, select, asc {oldest} desc {latest}}} reblogsCount {{sortOrder, select, asc {fewest boosts} desc {most boosts}}} favouritesCount {{sortOrder, select, asc {fewest likes} desc {most likes}}} repliesCount {{sortOrder, select, asc {fewest replies} desc {most replies}}} density {{sortOrder, select, asc {least dense} desc {most dense}}}} first{groupBy, select, account {, grouped by authors} other {}}"

#: src/pages/catchup.jsx:882
#: src/pages/catchup.jsx:906
msgid "Catch-up <0>beta</0>"
msgstr "Catch-up <0>beta</0>"

#: src/pages/catchup.jsx:896
#: src/pages/catchup.jsx:1585
msgid "Help"
msgstr "Help"

#: src/pages/catchup.jsx:912
msgid "What is this?"
msgstr "What is this?"

#: src/pages/catchup.jsx:915
msgid "Catch-up is a separate timeline for your followings, offering a high-level view at a glance, with a simple, email-inspired interface to effortlessly sort and filter through posts."
msgstr "Catch-up is a separate timeline for your followings, offering a high-level view at a glance, with a simple, email-inspired interface to effortlessly sort and filter through posts."

#: src/pages/catchup.jsx:926
msgid "Preview of Catch-up UI"
msgstr "Preview of Catch-up UI"

#: src/pages/catchup.jsx:935
msgid "Let's catch up"
msgstr "Let's catch up"

#: src/pages/catchup.jsx:940
msgid "Let's catch up on the posts from your followings."
msgstr "Let's catch up on the posts from your followings."

#: src/pages/catchup.jsx:944
msgid "Show me all posts from…"
msgstr "Show me all posts from…"

#: src/pages/catchup.jsx:967
msgid "until the max"
msgstr "until the max"

#: src/pages/catchup.jsx:997
msgid "Catch up"
msgstr "Catch up"

#: src/pages/catchup.jsx:1003
msgid "Overlaps with your last catch-up"
msgstr "Overlaps with your last catch-up"

#: src/pages/catchup.jsx:1015
msgid "Until the last catch-up ({0})"
msgstr "Until the last catch-up ({0})"

#: src/pages/catchup.jsx:1024
msgid "Note: your instance might only show a maximum of 800 posts in the Home timeline regardless of the time range. Could be less or more."
msgstr "Note: your instance might only show a maximum of 800 posts in the Home timeline regardless of the time range. Could be less or more."

#: src/pages/catchup.jsx:1034
msgid "Previously…"
msgstr "Previously…"

#: src/pages/catchup.jsx:1052
msgid "{0, plural, one {# post} other {# posts}}"
msgstr "{0, plural, one {# post} other {# posts}}"

#: src/pages/catchup.jsx:1062
msgid "Remove this catch-up?"
msgstr "Remove this catch-up?"

#: src/pages/catchup.jsx:1083
msgid "Note: Only max 3 will be stored. The rest will be automatically removed."
msgstr "Note: Only max 3 will be stored. The rest will be automatically removed."

#: src/pages/catchup.jsx:1098
msgid "Fetching posts…"
msgstr "Fetching posts…"

#: src/pages/catchup.jsx:1101
msgid "This might take a while."
msgstr "This might take a while."

#: src/pages/catchup.jsx:1136
msgid "Reset filters"
msgstr "Reset filters"

#: src/pages/catchup.jsx:1144
#: src/pages/catchup.jsx:1591
msgid "Top links"
msgstr "Top links"

#: src/pages/catchup.jsx:1261
msgid "Shared by {0}"
msgstr "Shared by {0}"

#: src/pages/catchup.jsx:1316
#: src/pages/mentions.jsx:170
#: src/pages/search.jsx:222
msgid "All"
msgstr "All"

#: src/pages/catchup.jsx:1401
msgid "{0, plural, one {# author} other {# authors}}"
msgstr "{0, plural, one {# author} other {# authors}}"

#: src/pages/catchup.jsx:1413
msgid "Sort"
msgstr "Sort"

#: src/pages/catchup.jsx:1444
msgid "Date"
msgstr "Date"

#: src/pages/catchup.jsx:1448
msgid "Density"
msgstr "Density"

#: src/pages/catchup.jsx:1486
msgid "Authors"
msgstr "Authors"

#: src/pages/catchup.jsx:1487
msgid "None"
msgstr "None"

#: src/pages/catchup.jsx:1503
msgid "Show all authors"
msgstr "Show all authors"

#: src/pages/catchup.jsx:1554
msgid "You don't have to read everything."
msgstr "You don't have to read everything."

#: src/pages/catchup.jsx:1555
msgid "That's all."
msgstr "That's all."

#: src/pages/catchup.jsx:1563
msgid "Back to top"
msgstr "Back to top"

#: src/pages/catchup.jsx:1594
msgid "Links shared by followings, sorted by shared counts, boosts and likes."
msgstr "Links shared by followings, sorted by shared counts, boosts and likes."

#: src/pages/catchup.jsx:1600
msgid "Sort: Density"
msgstr "Sort: Density"

#: src/pages/catchup.jsx:1603
msgid "Posts are sorted by information density or depth. Shorter posts are \"lighter\" while longer posts are \"heavier\". Posts with photos are \"heavier\" than posts without photos."
msgstr "Posts are sorted by information density or depth. Shorter posts are \"lighter\" while longer posts are \"heavier\". Posts with photos are \"heavier\" than posts without photos."

#: src/pages/catchup.jsx:1610
msgid "Group: Authors"
msgstr "Group: Authors"

#: src/pages/catchup.jsx:1613
msgid "Posts are grouped by authors, sorted by posts count per author."
msgstr "Posts are grouped by authors, sorted by posts count per author."

#: src/pages/catchup.jsx:1660
msgid "Next author"
msgstr "Next author"

#: src/pages/catchup.jsx:1668
msgid "Previous author"
msgstr "Previous author"

#: src/pages/catchup.jsx:1684
msgid "Scroll to top"
msgstr "Scroll to top"

#: src/pages/catchup.jsx:1875
msgid "Filtered: {0}"
msgstr "Filtered: {0}"

#: src/pages/favourites.jsx:25
msgid "No likes yet. Go like something!"
msgstr "No likes yet. Go like something!"

#: src/pages/favourites.jsx:26
msgid "Unable to load likes."
msgstr "Unable to load likes."

#: src/pages/filters.jsx:23
msgid "Home and lists"
msgstr "Home and lists"

#: src/pages/filters.jsx:25
msgid "Public timelines"
msgstr "Public timelines"

#: src/pages/filters.jsx:26
msgid "Conversations"
msgstr "Conversations"

#: src/pages/filters.jsx:27
msgid "Profiles"
msgstr "Profiles"

#: src/pages/filters.jsx:42
msgid "Never"
msgstr "Never"

#: src/pages/filters.jsx:103
#: src/pages/filters.jsx:228
msgid "New filter"
msgstr "New filter"

#: src/pages/filters.jsx:151
msgid "{0, plural, one {# filter} other {# filters}}"
msgstr "{0, plural, one {# filter} other {# filters}}"

#: src/pages/filters.jsx:166
msgid "Unable to load filters."
msgstr "Unable to load filters."

#: src/pages/filters.jsx:170
msgid "No filters yet."
msgstr "No filters yet."

#: src/pages/filters.jsx:177
msgid "Add filter"
msgstr "Add filter"

#: src/pages/filters.jsx:228
msgid "Edit filter"
msgstr "Edit filter"

#: src/pages/filters.jsx:345
msgid "Unable to edit filter"
msgstr "Unable to edit filter"

#: src/pages/filters.jsx:346
msgid "Unable to create filter"
msgstr "Unable to create filter"

#: src/pages/filters.jsx:355
msgid "Title"
msgstr "Title"

#: src/pages/filters.jsx:396
msgid "Whole word"
msgstr "Whole word"

#: src/pages/filters.jsx:422
msgid "No keywords. Add one."
msgstr "No keywords. Add one."

#: src/pages/filters.jsx:449
msgid "Add keyword"
msgstr "Add keyword"

#: src/pages/filters.jsx:453
msgid "{0, plural, one {# keyword} other {# keywords}}"
msgstr "{0, plural, one {# keyword} other {# keywords}}"

#: src/pages/filters.jsx:466
msgid "Filter from…"
msgstr "Filter from…"

#: src/pages/filters.jsx:492
msgid "* Not implemented yet"
msgstr "* Not implemented yet"

#: src/pages/filters.jsx:498
msgid "Status: <0><1/></0>"
msgstr "Status: <0><1/></0>"

#: src/pages/filters.jsx:507
msgid "Change expiry"
msgstr "Change expiry"

#: src/pages/filters.jsx:507
msgid "Expiry"
msgstr "Expiry"

#: src/pages/filters.jsx:526
msgid "Filtered post will be…"
msgstr "Filtered post will be…"

#: src/pages/filters.jsx:536
msgid "minimized"
msgstr "minimized"

#: src/pages/filters.jsx:546
msgid "hidden"
msgstr "hidden"

#: src/pages/filters.jsx:563
msgid "Delete this filter?"
msgstr "Delete this filter?"

#: src/pages/filters.jsx:576
msgid "Unable to delete filter."
msgstr "Unable to delete filter."

#: src/pages/filters.jsx:608
msgid "Expired"
msgstr "Expired"

#: src/pages/filters.jsx:610
msgid "Expiring <0/>"
msgstr "Expiring <0/>"

#: src/pages/filters.jsx:614
msgid "Never expires"
msgstr "Never expires"

#: src/pages/followed-hashtags.jsx:70
msgid "{0, plural, one {# hashtag} other {# hashtags}}"
msgstr "{0, plural, one {# hashtag} other {# hashtags}}"

#: src/pages/followed-hashtags.jsx:85
msgid "Unable to load followed hashtags."
msgstr "Unable to load followed hashtags."

#: src/pages/followed-hashtags.jsx:89
msgid "No hashtags followed yet."
msgstr "No hashtags followed yet."

#: src/pages/following.jsx:136
msgid "Nothing to see here."
msgstr "Nothing to see here."

#: src/pages/following.jsx:137
#: src/pages/list.jsx:108
msgid "Unable to load posts."
msgstr "Unable to load posts."

#: src/pages/hashtag.jsx:55
msgid "{hashtagTitle} (Media only) on {instance}"
msgstr "{hashtagTitle} (Media only) on {instance}"

#: src/pages/hashtag.jsx:56
msgid "{hashtagTitle} on {instance}"
msgstr "{hashtagTitle} on {instance}"

#: src/pages/hashtag.jsx:58
msgid "{hashtagTitle} (Media only)"
msgstr "{hashtagTitle} (Media only)"

#: src/pages/hashtag.jsx:59
msgid "{hashtagTitle}"
msgstr "{hashtagTitle}"

#: src/pages/hashtag.jsx:181
msgid "No one has posted anything with this tag yet."
msgstr "No one has posted anything with this tag yet."

#: src/pages/hashtag.jsx:182
msgid "Unable to load posts with this tag"
msgstr "Unable to load posts with this tag"

#: src/pages/hashtag.jsx:208
msgid "Unfollow #{hashtag}?"
msgstr "Unfollow #{hashtag}?"

#: src/pages/hashtag.jsx:223
msgid "Unfollowed #{hashtag}"
msgstr "Unfollowed #{hashtag}"

#: src/pages/hashtag.jsx:238
msgid "Followed #{hashtag}"
msgstr "Followed #{hashtag}"

#: src/pages/hashtag.jsx:254
msgid "Following…"
msgstr "Following…"

#: src/pages/hashtag.jsx:282
msgid "Unfeatured on profile"
msgstr "Unfeatured on profile"

#: src/pages/hashtag.jsx:296
msgid "Unable to unfeature on profile"
msgstr "Unable to unfeature on profile"

#: src/pages/hashtag.jsx:305
#: src/pages/hashtag.jsx:321
msgid "Featured on profile"
msgstr "Featured on profile"

#: src/pages/hashtag.jsx:328
msgid "Feature on profile"
msgstr "Feature on profile"

#: src/pages/hashtag.jsx:393
msgid "{TOTAL_TAGS_LIMIT, plural, other {Max # tags}}"
msgstr "{TOTAL_TAGS_LIMIT, plural, other {Max # tags}}"

#: src/pages/hashtag.jsx:396
msgid "Add hashtag"
msgstr "Add hashtag"

#: src/pages/hashtag.jsx:428
msgid "Remove hashtag"
msgstr "Remove hashtag"

#: src/pages/hashtag.jsx:442
msgid "{SHORTCUTS_LIMIT, plural, one {Max # shortcut reached. Unable to add shortcut.} other {Max # shortcuts reached. Unable to add shortcut.}}"
msgstr "{SHORTCUTS_LIMIT, plural, one {Max # shortcut reached. Unable to add shortcut.} other {Max # shortcuts reached. Unable to add shortcut.}}"

#: src/pages/hashtag.jsx:471
msgid "This shortcut already exists"
msgstr "This shortcut already exists"

#: src/pages/hashtag.jsx:474
msgid "Hashtag shortcut added"
msgstr "Hashtag shortcut added"

#: src/pages/hashtag.jsx:480
msgid "Add to Shortcuts"
msgstr "Add to Shortcuts"

#: src/pages/hashtag.jsx:486
#: src/pages/public.jsx:140
#: src/pages/trending.jsx:479
msgid "Enter a new instance e.g. \"mastodon.social\""
msgstr "Enter a new instance e.g. \"mastodon.social\""

#: src/pages/hashtag.jsx:489
#: src/pages/public.jsx:143
#: src/pages/trending.jsx:482
msgid "Invalid instance"
msgstr "Invalid instance"

#: src/pages/hashtag.jsx:503
#: src/pages/public.jsx:157
#: src/pages/trending.jsx:494
msgid "Go to another instance…"
msgstr "Go to another instance…"

#: src/pages/hashtag.jsx:516
#: src/pages/public.jsx:170
#: src/pages/trending.jsx:505
msgid "Go to my instance (<0>{currentInstance}</0>)"
msgstr "Go to my instance (<0>{currentInstance}</0>)"

#: src/pages/home.jsx:209
msgid "Unable to fetch notifications."
msgstr "Unable to fetch notifications."

#: src/pages/home.jsx:229
msgid "<0>New</0> <1>Follow Requests</1>"
msgstr "<0>New</0> <1>Follow Requests</1>"

#: src/pages/home.jsx:235
msgid "See all"
msgstr "See all"

#: src/pages/http-route.jsx:68
msgid "Resolving…"
msgstr "Resolving…"

#: src/pages/http-route.jsx:79
msgid "Unable to resolve URL"
msgstr "Unable to resolve URL"

#: src/pages/list.jsx:107
msgid "Nothing yet."
msgstr "Nothing yet."

#: src/pages/list.jsx:177
#: src/pages/list.jsx:280
msgid "Manage members"
msgstr "Manage members"

#: src/pages/list.jsx:314
msgid "Remove <0>@{0}</0> from list?"
msgstr "Remove <0>@{0}</0> from list?"

#: src/pages/list.jsx:360
msgid "Remove…"
msgstr "Remove…"

#: src/pages/lists.jsx:93
msgid "{0, plural, one {# list} other {# lists}}"
msgstr "{0, plural, one {# list} other {# lists}}"

#: src/pages/lists.jsx:108
msgid "No lists yet."
msgstr "No lists yet."

#: src/pages/login.jsx:109
#: src/pages/login.jsx:122
msgid "Failed to register application"
msgstr "Failed to register application"

#: src/pages/login.jsx:208
msgid "instance domain"
msgstr "instance domain"

#: src/pages/login.jsx:232
msgid "e.g. “mastodon.social”"
msgstr "e.g. “mastodon.social”"

#: src/pages/login.jsx:243
msgid "Failed to log in. Please try again or try another instance."
msgstr "Failed to log in. Please try again or try another instance."

#: src/pages/login.jsx:255
msgid "Continue with {selectedInstanceText}"
msgstr "Continue with {selectedInstanceText}"

#: src/pages/login.jsx:256
msgid "Continue"
msgstr "Continue"

#: src/pages/login.jsx:264
msgid "Don't have an account? Create one!"
msgstr "Don't have an account? Create one!"

#: src/pages/mentions.jsx:21
msgid "Private mentions"
msgstr "Private mentions"

#: src/pages/mentions.jsx:182
msgid "Private"
msgstr "Private"

#: src/pages/mentions.jsx:209
msgid "No one mentioned you :("
msgstr "No one mentioned you :("

#: src/pages/mentions.jsx:210
msgid "Unable to load mentions."
msgstr "Unable to load mentions."

#: src/pages/notifications.jsx:103
msgid "You don't follow"
msgstr "You don't follow"

#: src/pages/notifications.jsx:104
msgid "Who don't follow you"
msgstr "Who don't follow you"

#: src/pages/notifications.jsx:105
msgid "With a new account"
msgstr "With a new account"

#: src/pages/notifications.jsx:106
msgid "Who unsolicitedly private mention you"
msgstr "Who unsolicitedly private mention you"

#: src/pages/notifications.jsx:107
msgid "Who are limited by server moderators"
msgstr "Who are limited by server moderators"

#: src/pages/notifications.jsx:539
#: src/pages/notifications.jsx:873
msgid "Notifications settings"
msgstr "Notifications settings"

#: src/pages/notifications.jsx:557
msgid "New notifications"
msgstr "New notifications"

#: src/pages/notifications.jsx:568
msgid "{0, plural, one {Announcement} other {Announcements}}"
msgstr "{0, plural, one {Announcement} other {Announcements}}"

#: src/pages/notifications.jsx:615
#: src/pages/settings.jsx:1123
msgid "Follow requests"
msgstr "Follow requests"

#: src/pages/notifications.jsx:620
msgid "{0, plural, one {# follow request} other {# follow requests}}"
msgstr "{0, plural, one {# follow request} other {# follow requests}}"

#: src/pages/notifications.jsx:675
msgid "{0, plural, one {Filtered notifications from # person} other {Filtered notifications from # people}}"
msgstr "{0, plural, one {Filtered notifications from # person} other {Filtered notifications from # people}}"

#: src/pages/notifications.jsx:741
msgid "Only mentions"
msgstr "Only mentions"

#: src/pages/notifications.jsx:745
msgid "Today"
msgstr "Today"

#: src/pages/notifications.jsx:750
msgid "You're all caught up."
msgstr "You're all caught up."

#: src/pages/notifications.jsx:773
msgid "Yesterday"
msgstr "Yesterday"

#: src/pages/notifications.jsx:821
msgid "Unable to load notifications"
msgstr "Unable to load notifications"

#: src/pages/notifications.jsx:900
msgid "Notifications settings updated"
msgstr "Notifications settings updated"

#: src/pages/notifications.jsx:908
msgid "Filter out notifications from people:"
msgstr "Filter out notifications from people:"

#: src/pages/notifications.jsx:922
msgid "Filter"
msgstr "Filter"

#: src/pages/notifications.jsx:925
msgid "Ignore"
msgstr "Ignore"

#: src/pages/notifications.jsx:998
msgid "Updated <0>{0}</0>"
msgstr "Updated <0>{0}</0>"

#: src/pages/notifications.jsx:1066
msgid "View notifications from <0>@{0}</0>"
msgstr "View notifications from <0>@{0}</0>"

#: src/pages/notifications.jsx:1087
msgid "Notifications from <0>@{0}</0>"
msgstr "Notifications from <0>@{0}</0>"

#: src/pages/notifications.jsx:1154
msgid "Notifications from @{0} will not be filtered from now on."
msgstr "Notifications from @{0} will not be filtered from now on."

#: src/pages/notifications.jsx:1159
msgid "Unable to accept notification request"
msgstr "Unable to accept notification request"

#: src/pages/notifications.jsx:1164
msgid "Allow"
msgstr "Allow"

#: src/pages/notifications.jsx:1184
msgid "Notifications from @{0} will not show up in Filtered notifications from now on."
msgstr "Notifications from @{0} will not show up in Filtered notifications from now on."

#: src/pages/notifications.jsx:1189
msgid "Unable to dismiss notification request"
msgstr "Unable to dismiss notification request"

#: src/pages/notifications.jsx:1194
msgid "Dismiss"
msgstr "Dismiss"

#: src/pages/notifications.jsx:1209
msgid "Dismissed"
msgstr "Dismissed"

#: src/pages/public.jsx:27
msgid "Local timeline ({instance})"
msgstr "Local timeline ({instance})"

#: src/pages/public.jsx:28
msgid "Federated timeline ({instance})"
msgstr "Federated timeline ({instance})"

#: src/pages/public.jsx:90
msgid "Local timeline"
msgstr "Local timeline"

#: src/pages/public.jsx:90
msgid "Federated timeline"
msgstr "Federated timeline"

#: src/pages/public.jsx:96
msgid "No one has posted anything yet."
msgstr "No one has posted anything yet."

#: src/pages/public.jsx:124
msgid "Switch to Federated"
msgstr "Switch to Federated"

#: src/pages/public.jsx:131
msgid "Switch to Local"
msgstr "Switch to Local"

#: src/pages/search.jsx:43
msgid "Search: {q} (Posts)"
msgstr "Search: {q} (Posts)"

#: src/pages/search.jsx:46
msgid "Search: {q} (Accounts)"
msgstr "Search: {q} (Accounts)"

#: src/pages/search.jsx:49
msgid "Search: {q} (Hashtags)"
msgstr "Search: {q} (Hashtags)"

#: src/pages/search.jsx:52
msgid "Search: {q}"
msgstr "Search: {q}"

#: src/pages/search.jsx:232
#: src/pages/search.jsx:314
msgid "Hashtags"
msgstr "Hashtags"

#: src/pages/search.jsx:264
#: src/pages/search.jsx:318
#: src/pages/search.jsx:388
msgid "See more"
msgstr "See more"

#: src/pages/search.jsx:290
msgid "See more accounts"
msgstr "See more accounts"

#: src/pages/search.jsx:304
msgid "No accounts found."
msgstr "No accounts found."

#: src/pages/search.jsx:360
msgid "See more hashtags"
msgstr "See more hashtags"

#: src/pages/search.jsx:374
msgid "No hashtags found."
msgstr "No hashtags found."

#: src/pages/search.jsx:418
msgid "See more posts"
msgstr "See more posts"

#: src/pages/search.jsx:432
msgid "No posts found."
msgstr "No posts found."

#: src/pages/search.jsx:476
msgid "Enter your search term or paste a URL above to get started."
msgstr "Enter your search term or paste a URL above to get started."

#: src/pages/settings.jsx:74
msgid "Settings"
msgstr "Settings"

#: src/pages/settings.jsx:83
msgid "Appearance"
msgstr "Appearance"

#: src/pages/settings.jsx:159
msgid "Light"
msgstr "Light"

#: src/pages/settings.jsx:170
msgid "Dark"
msgstr "Dark"

#: src/pages/settings.jsx:183
msgid "Auto"
msgstr "Auto"

#: src/pages/settings.jsx:193
msgid "Text size"
msgstr "Text size"

#. Preview of one character, in smallest size
#. Preview of one character, in largest size
#: src/pages/settings.jsx:198
#: src/pages/settings.jsx:223
msgid "A"
msgstr "A"

#: src/pages/settings.jsx:237
msgid "Display language"
msgstr "Display language"

#: src/pages/settings.jsx:246
msgid "Volunteer translations"
msgstr "Volunteer translations"

#: src/pages/settings.jsx:257
msgid "Posting"
msgstr "Posting"

#: src/pages/settings.jsx:264
msgid "Default visibility"
msgstr "Default visibility"

#: src/pages/settings.jsx:265
#: src/pages/settings.jsx:311
msgid "Synced"
msgstr "Synced"

#: src/pages/settings.jsx:290
msgid "Failed to update posting privacy"
msgstr "Failed to update posting privacy"

#: src/pages/settings.jsx:313
msgid "Synced to your instance server's settings. <0>Go to your instance ({instance}) for more settings.</0>"
msgstr "Synced to your instance server's settings. <0>Go to your instance ({instance}) for more settings.</0>"

#: src/pages/settings.jsx:328
msgid "Experiments"
msgstr "Experiments"

#: src/pages/settings.jsx:341
msgid "Auto refresh timeline posts"
msgstr "Auto refresh timeline posts"

#: src/pages/settings.jsx:353
msgid "Boosts carousel"
msgstr "Boosts carousel"

#: src/pages/settings.jsx:381
msgid "Post translation"
msgstr "Post translation"

#: src/pages/settings.jsx:392
msgid "Translate to"
msgstr "Translate to"

#: src/pages/settings.jsx:403
msgid "System language ({systemTargetLanguageText})"
msgstr "System language ({systemTargetLanguageText})"

#: src/pages/settings.jsx:429
msgid "{0, plural, =0 {Hide \"Translate\" button for:} other {Hide \"Translate\" button for (#):}}"
msgstr "{0, plural, =0 {Hide \"Translate\" button for:} other {Hide \"Translate\" button for (#):}}"

#: src/pages/settings.jsx:483
msgid "Note: This feature uses external translation services, powered by <0>Lingva API</0> & <1>Lingva Translate</1>."
msgstr "Note: This feature uses external translation services, powered by <0>Lingva API</0> & <1>Lingva Translate</1>."

#: src/pages/settings.jsx:517
msgid "Auto inline translation"
msgstr "Auto inline translation"

#: src/pages/settings.jsx:521
msgid "Automatically show translation for posts in timeline. Only works for <0>short</0> posts without content warning, media and poll."
msgstr "Automatically show translation for posts in timeline. Only works for <0>short</0> posts without content warning, media and poll."

#: src/pages/settings.jsx:541
msgid "GIF Picker for composer"
msgstr "GIF Picker for composer"

#: src/pages/settings.jsx:545
msgid "Note: This feature uses external GIF search service, powered by <0>GIPHY</0>. G-rated (suitable for viewing by all ages), tracking parameters are stripped, referrer information is omitted from requests, but search queries and IP address information will still reach their servers."
msgstr "Note: This feature uses external GIF search service, powered by <0>GIPHY</0>. G-rated (suitable for viewing by all ages), tracking parameters are stripped, referrer information is omitted from requests, but search queries and IP address information will still reach their servers."

#: src/pages/settings.jsx:574
msgid "Image description generator"
msgstr "Image description generator"

#: src/pages/settings.jsx:579
msgid "Only for new images while composing new posts."
msgstr "Only for new images while composing new posts."

#: src/pages/settings.jsx:586
msgid "Note: This feature uses external AI service, powered by <0>img-alt-api</0>. May not work well. Only for images and in English."
msgstr "Note: This feature uses external AI service, powered by <0>img-alt-api</0>. May not work well. Only for images and in English."

#: src/pages/settings.jsx:612
msgid "Server-side grouped notifications"
msgstr "Server-side grouped notifications"

#: src/pages/settings.jsx:616
msgid "Alpha-stage feature. Potentially improved grouping window but basic grouping logic."
msgstr "Alpha-stage feature. Potentially improved grouping window but basic grouping logic."

#: src/pages/settings.jsx:637
msgid "\"Cloud\" import/export for shortcuts settings"
msgstr "\"Cloud\" import/export for shortcuts settings"

#: src/pages/settings.jsx:642
msgid "⚠️⚠️⚠️ Very experimental.<0/>Stored in your own profile’s notes. Profile (private) notes are mainly used for other profiles, and hidden for own profile."
msgstr "⚠️⚠️⚠️ Very experimental.<0/>Stored in your own profile’s notes. Profile (private) notes are mainly used for other profiles, and hidden for own profile."

#: src/pages/settings.jsx:653
msgid "Note: This feature uses currently-logged-in instance server API."
msgstr "Note: This feature uses currently-logged-in instance server API."

#: src/pages/settings.jsx:670
msgid "Cloak mode <0>(<1>Text</1> → <2>████</2>)</0>"
msgstr "Cloak mode <0>(<1>Text</1> → <2>████</2>)</0>"

#: src/pages/settings.jsx:679
msgid "Replace text as blocks, useful when taking screenshots, for privacy reasons."
msgstr "Replace text as blocks, useful when taking screenshots, for privacy reasons."

#: src/pages/settings.jsx:704
msgid "About"
msgstr "About"

#: src/pages/settings.jsx:743
msgid "<0>Built</0> by <1>@cheeaun</1>"
msgstr "<0>Built</0> by <1>@cheeaun</1>"

#: src/pages/settings.jsx:772
msgid "Sponsor"
msgstr "Sponsor"

#: src/pages/settings.jsx:780
msgid "Donate"
msgstr "Donate"

#: src/pages/settings.jsx:788
msgid "Privacy Policy"
msgstr "Privacy Policy"

#: src/pages/settings.jsx:795
msgid "<0>Site:</0> {0}"
msgstr "<0>Site:</0> {0}"

#: src/pages/settings.jsx:802
msgid "<0>Version:</0> <1/> {0}"
msgstr "<0>Version:</0> <1/> {0}"

#: src/pages/settings.jsx:817
msgid "Version string copied"
msgstr "Version string copied"

#: src/pages/settings.jsx:820
msgid "Unable to copy version string"
msgstr "Unable to copy version string"

#: src/pages/settings.jsx:1020
#: src/pages/settings.jsx:1025
msgid "Failed to update subscription. Please try again."
msgstr "Failed to update subscription. Please try again."

#: src/pages/settings.jsx:1031
msgid "Failed to remove subscription. Please try again."
msgstr "Failed to remove subscription. Please try again."

#: src/pages/settings.jsx:1038
msgid "Push Notifications (beta)"
msgstr "Push Notifications (beta)"

#: src/pages/settings.jsx:1060
msgid "Push notifications are blocked. Please enable them in your browser settings."
msgstr "Push notifications are blocked. Please enable them in your browser settings."

#: src/pages/settings.jsx:1069
msgid "Allow from <0>{0}</0>"
msgstr "Allow from <0>{0}</0>"

#: src/pages/settings.jsx:1078
msgid "anyone"
msgstr "anyone"

#: src/pages/settings.jsx:1082
msgid "people I follow"
msgstr "people I follow"

#: src/pages/settings.jsx:1086
msgid "followers"
msgstr "followers"

#: src/pages/settings.jsx:1119
msgid "Follows"
msgstr "Follows"

#: src/pages/settings.jsx:1127
msgid "Polls"
msgstr "Polls"

#: src/pages/settings.jsx:1131
msgid "Post edits"
msgstr "Post edits"

#: src/pages/settings.jsx:1152
msgid "Push permission was not granted since your last login. You'll need to <0><1>log in</1> again to grant push permission</0>."
msgstr "Push permission was not granted since your last login. You'll need to <0><1>log in</1> again to grant push permission</0>."

#: src/pages/settings.jsx:1168
msgid "NOTE: Push notifications only work for <0>one account</0>."
msgstr "NOTE: Push notifications only work for <0>one account</0>."

#: src/pages/status.jsx:565
msgid "Post"
msgstr "Post"

#: src/pages/status.jsx:786
msgid "You're not logged in. Interactions (reply, boost, etc) are not possible."
msgstr "You're not logged in. Interactions (reply, boost, etc) are not possible."

#: src/pages/status.jsx:799
msgid "This post is from another instance (<0>{instance}</0>). Interactions (reply, boost, etc) are not possible."
msgstr "This post is from another instance (<0>{instance}</0>). Interactions (reply, boost, etc) are not possible."

#: src/pages/status.jsx:827
msgid "Error: {e}"
msgstr "Error: {e}"

#: src/pages/status.jsx:834
msgid "Switch to my instance to enable interactions"
msgstr "Switch to my instance to enable interactions"

#: src/pages/status.jsx:936
msgid "Unable to load replies."
msgstr "Unable to load replies."

#: src/pages/status.jsx:1048
msgid "Back"
msgstr "Back"

#: src/pages/status.jsx:1079
msgid "Go to main post"
msgstr "Go to main post"

#: src/pages/status.jsx:1102
msgid "{0} posts above ‒ Go to top"
msgstr "{0} posts above ‒ Go to top"

#: src/pages/status.jsx:1145
#: src/pages/status.jsx:1208
msgid "Switch to Side Peek view"
msgstr "Switch to Side Peek view"

#: src/pages/status.jsx:1209
msgid "Switch to Full view"
msgstr "Switch to Full view"

#: src/pages/status.jsx:1227
msgid "Show all sensitive content"
msgstr "Show all sensitive content"

#: src/pages/status.jsx:1232
msgid "Experimental"
msgstr "Experimental"

#: src/pages/status.jsx:1241
msgid "Unable to switch"
msgstr "Unable to switch"

#: src/pages/status.jsx:1248
msgid "Switch to post's instance ({0})"
msgstr "Switch to post's instance ({0})"

#: src/pages/status.jsx:1251
msgid "Switch to post's instance"
msgstr "Switch to post's instance"

#: src/pages/status.jsx:1309
msgid "Unable to load post"
msgstr "Unable to load post"

#: src/pages/status.jsx:1426
msgid "{0, plural, one {# reply} other {<0>{1}</0> replies}}"
msgstr "{0, plural, one {# reply} other {<0>{1}</0> replies}}"

#: src/pages/status.jsx:1444
msgid "{totalComments, plural, one {# comment} other {<0>{0}</0> comments}}"
msgstr "{totalComments, plural, one {# comment} other {<0>{0}</0> comments}}"

#: src/pages/status.jsx:1466
msgid "View post with its replies"
msgstr "View post with its replies"

#: src/pages/trending.jsx:71
msgid "Trending ({instance})"
msgstr "Trending ({instance})"

#: src/pages/trending.jsx:228
msgid "Trending News"
msgstr "Trending News"

#. By [Author]
#: src/pages/trending.jsx:347
msgid "By {0}"
msgstr "By {0}"

#: src/pages/trending.jsx:408
msgid "Back to showing trending posts"
msgstr "Back to showing trending posts"

#: src/pages/trending.jsx:413
msgid "Showing posts mentioning <0>{0}</0>"
msgstr "Showing posts mentioning <0>{0}</0>"

#: src/pages/trending.jsx:425
msgid "Trending posts"
msgstr "Trending posts"

#: src/pages/trending.jsx:448
msgid "No trending posts."
msgstr "No trending posts."

#: src/pages/welcome.jsx:53
msgid "A minimalistic opinionated Mastodon web client."
msgstr "A minimalistic opinionated Mastodon web client."

#: src/pages/welcome.jsx:64
msgid "Log in with Mastodon"
msgstr "Log in with Mastodon"

#: src/pages/welcome.jsx:70
msgid "Sign up"
msgstr "Sign up"

#: src/pages/welcome.jsx:77
msgid "Connect your existing Mastodon/Fediverse account.<0/>Your credentials are not stored on this server."
msgstr "Connect your existing Mastodon/Fediverse account.<0/>Your credentials are not stored on this server."

#: src/pages/welcome.jsx:94
msgid "<0>Built</0> by <1>@cheeaun</1>. <2>Privacy Policy</2>."
msgstr "<0>Built</0> by <1>@cheeaun</1>. <2>Privacy Policy</2>."

#: src/pages/welcome.jsx:125
msgid "Screenshot of Boosts Carousel"
msgstr "Screenshot of Boosts Carousel"

#: src/pages/welcome.jsx:129
msgid "Boosts Carousel"
msgstr "Boosts Carousel"

#: src/pages/welcome.jsx:132
msgid "Visually separate original posts and re-shared posts (boosted posts)."
msgstr "Visually separate original posts and re-shared posts (boosted posts)."

#: src/pages/welcome.jsx:141
msgid "Screenshot of nested comments thread"
msgstr "Screenshot of nested comments thread"

#: src/pages/welcome.jsx:145
msgid "Nested comments thread"
msgstr "Nested comments thread"

#: src/pages/welcome.jsx:148
msgid "Effortlessly follow conversations. Semi-collapsible replies."
msgstr "Effortlessly follow conversations. Semi-collapsible replies."

#: src/pages/welcome.jsx:156
msgid "Screenshot of grouped notifications"
msgstr "Screenshot of grouped notifications"

#: src/pages/welcome.jsx:160
msgid "Grouped notifications"
msgstr "Grouped notifications"

#: src/pages/welcome.jsx:163
msgid "Similar notifications are grouped and collapsed to reduce clutter."
msgstr "Similar notifications are grouped and collapsed to reduce clutter."

#: src/pages/welcome.jsx:172
msgid "Screenshot of multi-column UI"
msgstr "Screenshot of multi-column UI"

#: src/pages/welcome.jsx:176
msgid "Single or multi-column"
msgstr "Single or multi-column"

#: src/pages/welcome.jsx:179
msgid "By default, single column for zen-mode seekers. Configurable multi-column for power users."
msgstr "By default, single column for zen-mode seekers. Configurable multi-column for power users."

#: src/pages/welcome.jsx:188
msgid "Screenshot of multi-hashtag timeline with a form to add more hashtags"
msgstr "Screenshot of multi-hashtag timeline with a form to add more hashtags"

#: src/pages/welcome.jsx:192
msgid "Multi-hashtag timeline"
msgstr "Multi-hashtag timeline"

#: src/pages/welcome.jsx:195
msgid "Up to 5 hashtags combined into a single timeline."
msgstr "Up to 5 hashtags combined into a single timeline."

#: src/utils/open-compose.js:24
msgid "Looks like your browser is blocking popups."
msgstr "Looks like your browser is blocking popups."

#: src/utils/show-compose.js:16
msgid "A draft post is currently minimized. Post or discard it before creating a new one."
msgstr "A draft post is currently minimized. Post or discard it before creating a new one."

#: src/utils/show-compose.js:21
msgid "A post is currently open. Post or discard it before creating a new one."
msgstr "A post is currently open. Post or discard it before creating a new one."
